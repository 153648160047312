import React from 'react'
import { Image, Link, Typography } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'
import style9 from 'style9'

import { RecommendedArticles } from '@project/scenes'
import { latestArticlesSelector } from '@project/state'

const styles = style9.create({
  base: {
    fontFamily: 'Inter, Roboto, Arial',
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 'var(--size-500, 16px)'
  },
  article: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(60px, 2fr) 10fr',
    gap: 'var(--size-500, 16px)'
  },
  image: {
    width: '100%',
    borderTopLeftRadius: 'var(--size-100, 8px)',
    borderTopRightRadius: 'var(--size-100, 8px)',
    borderBottomLeftRadius: 'var(--size-100, 8px)',
    borderBottomRightRadius: 'var(--size-100, 8px)'
  }
})

export const FeedContainer = (): React.ReactElement => {
  const { state, contents } = useRecoilValueLoadable(latestArticlesSelector)

  return (
    <>
      <RecommendedArticles />

      <div className={styles('base')}>
        <Typography
          testID={`title`}
          variant={'label'}
          text={`Latest`}
          weight={'heavy'}
          transform={'uppercase'}
        />

        <div style={{ display: 'grid', gap: '16px' }}>
          {state === 'loading' && 'Loading articles...'}
          {state === 'hasError' && <div>{contents.message}</div>}
          {state === 'hasValue' &&
            contents.map((article) => (
              <Link
                key={article.id}
                testID={`link`}
                href={`/blog/${article.id}`}
                className={styles('article')}
              >
                <Image
                  testID={`image`}
                  src={article.banner?.src}
                  className={styles('image')}
                />
                <div
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'row'
                  }}
                >
                  <Typography
                    testID={`title`}
                    variant={'title'}
                    text={article.title}
                  />
                  {article.subtitle && (
                    <Typography
                      testID={`snippet`}
                      variant={'body'}
                      text={article.subtitle}
                    />
                  )}
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  )
}
