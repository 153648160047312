import { atom, useRecoilState } from 'recoil'

import { localStorageEffect } from '../effects'

const ATOM_KEY = 'theme'

// TODO: setup `auto` mode which pulls from device/browser settings
type AppTheme = 'light' | 'dark' | 'auto'

const themeAtom = atom<AppTheme>({
  key: ATOM_KEY,
  default: 'light',
  effects: [localStorageEffect(ATOM_KEY)]
})

export const useTheme = (): { theme: AppTheme; toggleTheme: () => void } => {
  const [themeValue, setThemeValue] = useRecoilState(themeAtom)

  const toggleTheme = (): void =>
    setThemeValue(themeValue === 'light' ? 'dark' : 'light')

  return {
    theme: themeValue,
    /**
     * Flips the theme between `light` and `dark` mode,
     * depending on what's currently selected.
     */
    toggleTheme
  }
}
