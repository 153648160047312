import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useRecoilValueLoadable } from 'recoil'
import style9 from 'style9'

import { Article } from '@project/components'
import { articleSelector } from '@project/state'

const styles = style9.create({
  base: {
    display: 'grid',
    position: 'relative'
  }
})

export const ArticleScene = (): React.ReactElement => {
  const { t } = useTranslation()
  const params: { id?: string } = useParams()
  const testID = 'article'

  const { state, contents } = useRecoilValueLoadable(articleSelector(params.id))

  switch (state) {
    case 'loading':
      return (
        <main data-testid={testID} className={styles('base')}>
          <Article testID={`${testID}.article`} title={'Loading...'} />
        </main>
      )

    case 'hasError':
      return <div>Error: {contents.message}</div>

    case 'hasValue':
      return (
        <main data-testid={testID} className={styles('base')}>
          <Article
            testID={`${testID}.article`}
            title={contents?.title ?? ''}
            subtitle={contents?.subtitle ?? ''}
            content={contents?.content}
          />
        </main>
      )

    default:
      return (
        <div>
          {t('ArticleErrorUnknown', {
            defaultValue: `Unable to load the article`
          })}
        </div>
      )
  }
}
