import React from 'react'
import { Typography } from '@sigatoka/elements'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import style9 from 'style9'

import { HighlightGroup, HighlightGroupSkeleton } from '@project/components'
import {
  Collection,
  projectCategories,
  selectProjectGroups,
  useLayout
} from '@project/state'

const styles = style9.create({
  base: {
    maxWidth: 860,
    display: 'grid',
    gridAutoFlow: 'row',
    padding: 'var(--size-100)',
    gap: 'var(--size-100)'
  }
})

export const ProjectCategory = ({
  testID,
  type
}: {
  type: string
} & CommonProps): React.ReactElement | null => {
  const { title, projects } = useRecoilValue(projectCategories(type))

  const {
    display: { mode }
  } = useLayout()

  const { state, contents } = useRecoilValueLoadable(
    selectProjectGroups(projects)
  )

  switch (state) {
    case 'hasError':
      return <div>Error! {contents.message}</div>

    case 'hasValue':
      if (contents.length === 0) {
        return null
      }

      return (
        <div data-testid={testID} className={styles('base')}>
          <Typography
            testID={`${testID}.title`}
            variant={'attention'}
            font={'Inter, Quicksand, Open Sans, sans-serif'}
            transform={'uppercase'}
            weight={'heavy'}
            text={title}
          />

          {contents.map((group: Collection) => (
            <HighlightGroup
              testID={`${testID}.highlight`}
              key={group.key}
              media={group.media}
              title={group.title}
              mode={mode}
              startDate={group.startDate}
              endDate={group.endDate}
              items={group.items}
            />
          ))}
        </div>
      )

    case 'loading':
      return (
        <div data-testid={testID} className={styles('base')}>
          <Typography
            testID={`${testID}.title`}
            variant={'subheading'}
            transform={'uppercase'}
            text={title}
          />

          {Array.from(Array(4)).map((_, idx) => (
            <HighlightGroupSkeleton
              testID={`${testID}.skeleton`}
              key={idx}
              title
            />
          ))}
        </div>
      )

    default:
      return null
  }
}
