/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Drawer, Typography } from '@sigatoka/elements'
import lottie, { AnimationItem } from 'lottie-web-light'
import style9 from 'style9'

import { ICONS } from '@project/assets'
import { getRolePeriodText } from '@project/business'
import { DisplayMode, ProjectPeriod } from '@project/interfaces'
import { useDevToggles } from '@project/state'

import { TagCollection } from '../tag-collection/tag-collection'
import { getTimestampText } from './functions/get-timespan-text'

interface HighlightProps extends CommonProps {
  description?: string | string[]
  /**
   * Determines if the description text is displayed or
   * hidden until the expand prompt is clicked
   */
  expanded?: boolean
  subtitle?: string
  title: string
  startDate?: number
  endDate?: number
  period?: ProjectPeriod
  tags?: number[]
  mode?: DisplayMode
}

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: 'calc(var(--size-100, 11px) / 2)',
    cursor: 'default'
  },
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 'var(--size-100, 8px)',
    gridTemplateColumns: 'auto min-content'
  },
  expandable: {
    cursor: 'pointer'
  },
  icon: {
    width: 'var(--size-500)',
    height: 'var(--size-500)'
  },
  description: {
    margin: '0px',
    padding: 'var(--size-100, 11px) 0px 0px 0px'
  },
  tags: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gap: 'var(--size-100, 11px)',
    padding: 'var(--size-100, 11px)'
  },
  revealAnimation: {
    display: 'block',
    animationDuration: '300ms',
    animationDirection: 'forwards',
    animationTimingFunction: 'ease-in-out',
    transformOrigin: 'top center',
    animationName: style9.keyframes({
      from: {
        transform: 'scaleY(0.8)',
        opacity: 0
      },
      '80%': {
        transform: 'scaleY(1.01)',
        opacity: 1
      },
      to: {
        transform: 'scaleY(1)'
      }
    })
  },
  hideAnimation: {
    display: 'block',
    animationDuration: '300ms',
    animationDirection: 'forwards',
    animationTimingFunction: 'ease-in-out',
    transformOrigin: 'top center',
    animationName: style9.keyframes({
      from: {
        transform: 'scaleY(1)'
      },
      '20%': {
        transform: 'scaleY(1.01)',
        opacity: 1
      },
      to: {
        transform: 'scaleY(0.8)',
        opacity: 0
      }
    })
  },
  skeleton: {
    userSelect: 'none',
    color: 'transparent',
    backgroundColor: 'var(--color-900)',
    borderTopLeftRadius: 'var(--size-100)',
    borderTopRightRadius: 'var(--size-100)',
    borderBottomLeftRadius: 'var(--size-100)',
    borderBottomRightRadius: 'var(--size-100)'
  },
  noSelect: {
    userSelect: 'none'
  }
})

const getSubtitleText = (
  fromDate?: number,
  toDate?: number,
  period?: ProjectPeriod
): string =>
  [getTimestampText(fromDate, toDate), getRolePeriodText(period)]
    .filter((val) => !!val)
    .join(', ')

export const Highlight = ({
  testID,
  description,
  endDate,
  expanded = false,
  subtitle,
  startDate,
  title,
  tags = [],
  mode,
  period
}: HighlightProps): React.ReactElement => {
  const iconRef = useRef<HTMLSpanElement>(null)
  const animationRef = useRef<AnimationItem>()
  const { isEnabled } = useDevToggles()

  const [isExpanded, setIsExpanded] = useState(
    mode === DisplayMode.Print || expanded
  )

  const toggleExpanded = useCallback(
    () => setIsExpanded((expanded) => mode !== DisplayMode.Print && !expanded),
    [setIsExpanded, mode]
  )

  const toggleHovered = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (animationRef.current && event.type === 'mouseenter') {
        animationRef.current.playSegments(
          [
            [60, 70],
            [70, 60]
          ],
          true
        )
      }
    },
    [animationRef.current]
  )

  const handleKeyPress = useCallback(
    ({ key }: React.KeyboardEvent<HTMLDivElement>): void => {
      if (key === 'Enter') {
        toggleExpanded()
      }
    },
    [toggleExpanded]
  )

  useEffect(() => {
    if (!animationRef.current && iconRef.current) {
      animationRef.current = lottie.loadAnimation({
        name: 'Highlight',
        animationData: ICONS['dropdown'].default,
        renderer: 'svg',
        initialSegment: [13, 60],
        loop: false,
        autoplay: true,
        container: iconRef.current
      })
    }

    return (): void => animationRef.current?.destroy('Highlight')
  }, [])

  return (
    <div
      data-testid={testID}
      role={'region'}
      tabIndex={-1}
      className={styles('base', { expandable: !!description })}
      onKeyPress={handleKeyPress}
      onMouseEnter={toggleHovered}
      onMouseLeave={toggleHovered}
      onClick={toggleExpanded}
    >
      <div className={styles('header', 'noSelect')}>
        <Typography testID={`${testID}.title`} variant={'title'} text={title} />
        {description && mode !== DisplayMode.Print && (
          <span ref={iconRef} className={styles('icon')} />
        )}
      </div>

      {subtitle && (
        <Typography
          testID={`${testID}.subtitle`}
          variant={'body'}
          text={getSubtitleText(startDate, endDate, period)}
          className={styles('noSelect')}
        />
      )}

      {description && (
        <Drawer testID={`${testID}.drawer`} show={isExpanded} animated>
          <div data-testid={`${testID}.details`}>
            {isEnabled('tags') && (
              <TagCollection testID={`${testID}.tags`} tags={tags} />
            )}
            <ul
              data-testid={`${testID}.description`}
              className={styles('description', {
                revealAnimation: isExpanded,
                hideAnimation: !isExpanded
              })}
            >
              {('object' === typeof description
                ? description
                : [description]
              ).map((text, idx) => (
                <Typography
                  testID={`${testID}.item`}
                  key={`${text}-${idx}`}
                  component={'li'}
                  variant={'list'}
                  text={text}
                  className={styles('noSelect')}
                />
              ))}
            </ul>
          </div>
        </Drawer>
      )}
    </div>
  )
}

export const HighlightSkeleton = ({
  testID,
  subtitle
}: CommonProps & {
  subtitle?: boolean
}): React.ReactElement => (
  <div data-testid={testID} className={styles('base')}>
    <div className={styles('header')}>
      <Typography
        testID={`${testID}.title`}
        variant={'title'}
        text={'Loading...'}
        className={styles('skeleton')}
      />
    </div>

    {subtitle && (
      <Typography
        testID={`${testID}.subtitle`}
        variant={'body'}
        text={'Loading...'}
        className={styles('skeleton')}
      />
    )}
  </div>
)
