import React, { useEffect, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import { DisplayMode } from '@project/interfaces'
import { layoutState } from '@project/state'

const DeepLinkHandler = ({
  children
}: React.PropsWithChildren): React.ReactNode => {
  const setLayout = useSetRecoilState(layoutState)
  const view = useMemo(() => {
    const query = new URLSearchParams(location.search)

    return query.get('view') as DisplayMode | null
  }, [location])

  useEffect(() => {
    if (view && [DisplayMode.Print, DisplayMode.Default].includes(view)) {
      setLayout((prevLayout) => ({
        ...prevLayout,
        display: {
          ...prevLayout.display,
          mode: view
        }
      }))
    }
  }, [view])

  return children
}

export { DeepLinkHandler }
