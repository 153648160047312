import { ArticleAssetMedia } from './asset.interface'

export enum ContentType {
  /**
   * Text based block containing simple markup such
   * as bold, italic, underscore, and strike-through
   */
  Text = 0,
  /**
   * Emphasizes the content as a section
   * heading
   */
  Heading,
  /**
   * Emphasizes the content as a section
   * subheading
   */
  Subheading,
  /**
   * Embedded YouTube or Video content
   */
  Video,
  /**
   * Image resource with optional image caption
   */
  Image,
  /**
   * Code snippet with optional programming language
   */
  Code,
  /**
   * Featured link content. Attempts to retrieve
   * summarized details about the linked page, and
   * display a small highlight
   */
  Link,
  Action
}

export type ArticleContentHeading = ArticleContentText & {
  type: ContentType.Heading
}

export type ArticleContentSubheading = ArticleContentText & {
  type: ContentType.Subheading
}

export type ArticleContentParagraph = ArticleContentText & {
  type: ContentType.Text
  body: string
}

type ArticleContentText = ArticleContent & {
  text: string
}

export type ArticleContentCode = ArticleContent & {
  type: ContentType.Code
  language?: string
  /**
   * Raw source code for interpretation
   */
  source: string
}

export type ArticleContentLink = ArticleContent & {
  type: ContentType.Link
  /**
   * Full URL path to link the user to on click.
   */
  link: string

  title?: string

  description?: string

  media?: ArticleAssetMedia
}

export type ArticleContentAction = ArticleContent & {
  type: ContentType.Action

  title: string

  description?: string

  resource: CommonResource
}

type ArticleContent = {
  /**
   * Unique key representing a shortened paragraph
   * identifier
   */
  key: string
  /**
   * Optional content type, used to determine the
   * layout method of content
   */
  type?: ContentType

  active?: boolean

  flagged?: boolean
  /**
   * Specifies if the content is restricted to a collection
   * of individual accounts, as specified in the `permissions`
   * attribute.
   *
   * Should be assumed `false` when `undefined`, making
   * content private by default.
   */
  public?: boolean
}
