import { MutableSnapshot } from 'recoil'

import {
  locationState,
  orgState,
  projectCategories,
  projectState,
  skillState
} from '@project/state'

import data from './data.json'

const loadState = ({ set }: MutableSnapshot): void => {
  data.skills.forEach((label, key) =>
    set(skillState(key), {
      key,
      label
    })
  )

  data.locations.forEach(({ id, ...location }) =>
    set(locationState(id), {
      id,
      city: location.city ?? '',
      country: location.country ?? '',
      label: location.label ?? null
    })
  )

  data.orgs.forEach(({ id, ...org }) =>
    set(orgState(id), {
      id,
      name: org.name,
      nickname: org.displayName,
      thumb: id
    })
  )

  data.projects.forEach(({ id, ...project }) =>
    set(projectState(id), {
      id,
      ...project
    })
  )

  data.education.forEach(({ id, ...project }) =>
    set(projectState(id), {
      id,
      ...project
    })
  )

  data.awards.forEach(({ id, ...project }) =>
    set(projectState(id), {
      id,
      ...project
    })
  )

  set(projectCategories('experience'), {
    key: 'experience',
    title: 'Experience',
    projects: data.projects.map(({ id }) => id)
  })

  set(projectCategories('education'), {
    key: 'education',
    title: 'Education',
    projects: data.education.map(({ id }) => id)
  })

  set(projectCategories('awards'), {
    key: 'awards',
    title: 'Awards',
    projects: data.awards.map(({ id }) => id)
  })

  set(projectCategories('publications'), {
    key: 'publications',
    title: 'Publications',
    projects: data.publications.map(({ id }) => id)
  })
}

export { loadState }
