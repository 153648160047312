import React, { useCallback } from 'react'
import { Button, Input, Typography } from '@sigatoka/elements'
import { useRecoilState } from 'recoil'
import style9 from 'style9'

import { selectedElementId } from '@project/state/financial/expense.state'

import { expenseStateFamily } from '../../../state/financial/expense.state'
import { ExpenseFrequency } from '../tool-scene.interface'

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 'calc(var(--size-100)/2)'
  },
  selected: {
    border: '1px solid red'
  },
  horizontal: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 'var(--size-100)'
  }
})

const frequencies = Object.entries(ExpenseFrequency)

export const getPriceText = (str: string | number): string =>
  `$${Number(str).toFixed(2).toString()}`

export const ExpenseRow = ({
  testID,
  id
}: CommonProps & { id: string }): React.ReactElement => {
  const [expense, updateExpense] = useRecoilState(expenseStateFamily(id))
  const [selectedKey, setSelectedExpense] = useRecoilState(selectedElementId)

  const deselect = useCallback(() => setSelectedExpense(null), [id])

  const selectExpense = useCallback(() => setSelectedExpense(id), [id])

  const updateAttribute = useCallback(
    ({
      currentTarget: { value, name }
    }: React.FormEvent<HTMLInputElement | HTMLSelectElement>): void =>
      updateExpense((state) =>
        state
          ? {
              ...state,
              [name]: 'number' === typeof value ? Number(value) : value
            }
          : state
      ),
    [id]
  )

  return (
    <div
      data-testid={testID}
      role={'gridcell'}
      // onBlur={deselect}
      className={styles('base', {
        selected: selectedKey === id
      })}
    >
      {selectedKey === id ? (
        <>
          <Input
            testID={`${testID}.label`}
            tabIndex={0}
            name={'label'}
            label={`Name`}
            onChange={updateAttribute}
            defaultValue={expense.label ?? undefined}
          />
          <Input
            testID={`${testID}.value`}
            tabIndex={-1}
            name={'value'}
            label={`Value`}
            onChange={updateAttribute}
            defaultValue={expense.value}
          />
          <select
            name={'frequency'}
            defaultValue={expense.frequency}
            onBlur={updateAttribute}
          >
            {frequencies.map(([key, value]) => (
              <option key={key.toLowerCase()} value={value}>
                {key}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>
          <Typography
            testID={`${testID}.label`}
            text={expense.label ?? `Expense`}
          />
          <Typography
            testID={`${testID}.value`}
            text={getPriceText(expense.value)}
          />
          <Button
            testID={`${testID}.edit`}
            variant={'link'}
            onClick={selectExpense}
            text={`edit`}
          />
        </>
      )}
    </div>
  )
}
