/* eslint-disable no-magic-numbers */

import { ExpenseFrequency } from './tool-scene.interface'

/**
 *
 * @param value Raw numerical value
 * @param frequency Frequency of the raw value
 * @returns Value per millisecond
 */
const normalizeValue = (value: number, frequency: ExpenseFrequency): number => {
  if (frequency === ExpenseFrequency.Yearly) {
    return value / 31536000000
  }

  if (frequency === ExpenseFrequency.Quarterly) {
    return value / 7884000000
  }

  if (frequency === ExpenseFrequency.Monthly) {
    return value / 2628000000
  }

  if (frequency === ExpenseFrequency.Fortnightly) {
    return value / 1212923077
  }

  if (frequency === ExpenseFrequency.Weekly) {
    return value / 606461538
  }

  if (frequency === ExpenseFrequency.Daily) {
    return value / 86400000
  }

  if (frequency === ExpenseFrequency.Hourly) {
    return value / 3600000
  }

  return value
}

const convertFrequency = (
  value: number,
  frequency: ExpenseFrequency
): number => {
  if (frequency === ExpenseFrequency.Yearly) {
    return value * 21600000000 // per working year // 31536000000 // per year
  }

  if (frequency === ExpenseFrequency.Quarterly) {
    return value * 7884000000
  }

  if (frequency === ExpenseFrequency.Monthly) {
    return value * 2628000000
  }

  if (frequency === ExpenseFrequency.Fortnightly) {
    return value * 1212923077
  }

  if (frequency === ExpenseFrequency.Weekly) {
    return value * 606461538
  }

  if (frequency === ExpenseFrequency.Daily) {
    return value * 86400000
  }

  if (frequency === ExpenseFrequency.Hourly) {
    return value * 3600000
  }

  return value
}

export { convertFrequency, normalizeValue }
