import React, { useMemo } from 'react'
import { Pill } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'
import style9 from 'style9'

import { skillState } from '@project/state'

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gap: '6px',
    padding: '4px'
  }
})

interface TagCollectionProps extends CommonProps {
  tags: number[]
}

const TagItem = ({
  testID,
  tagIndex
}: CommonProps & { tagIndex: number }): React.ReactElement => {
  const { state, contents } = useRecoilValueLoadable(skillState(tagIndex))

  const label = useMemo(() => {
    if (state === 'hasValue' && contents) {
      return contents.label
    }

    return null
  }, [state, contents])

  switch (state) {
    case 'loading':
      return <Pill testID={testID} label={'Loading'} loading />

    case 'hasError':
      return <div />

    case 'hasValue':
      return <Pill testID={testID} label={label} />

    default:
      return <div />
  }
}

export const TagCollection = ({
  testID,
  tags = []
}: TagCollectionProps): React.ReactElement | null => (
  <div data-testid={testID} className={styles('base')}>
    {tags.map((key) => (
      <TagItem testID={`${testID}.tag`} key={`tag-${key}`} tagIndex={key} />
    ))}
  </div>
)
