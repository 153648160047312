import React from 'react'
import {
  BrowserRouter as Router,
  Route as Page,
  Routes as Switch
} from 'react-router-dom'
import style9 from 'style9'

import { SubscribeBanner } from '@project/components'
import { FeedContainer } from '@project/containers'
import { useFeatures } from '@project/hooks'

import { ArticleScene } from '../article-scene'

const styles = style9.create({
  base: {
    fontFamily: 'Inter, Roboto, Arial',
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 'var(--size-500, 16px)'
  }
})

export const BlogScene = (): React.ReactElement => {
  const isSubscribed = false
  const { isEnabled } = useFeatures()

  return (
    <div className={styles('base')}>
      {!isSubscribed && isEnabled('subscriptions') && (
        <SubscribeBanner testID={`subscribe`} />
      )}

      <Router basename={`blog`}>
        <Switch>
          <Page path={'/:id'} element={<ArticleScene />} />
          <Page element={<FeedContainer />} index />
        </Switch>
      </Router>
    </div>
  )
}
