import React from 'react'
import { Typography } from '@sigatoka/elements'
import { useRecoilValue } from 'recoil'
import style9 from 'style9'

import { wealthSelector } from '@project/state/financial/expense.state'

import { getPriceText } from './components/expense-row'

const styles = style9.create({
  base: {},
  field: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  invalid: {
    color: 'red',
    fontWeight: 500
  }
})

const ValueOutput = ({
  label,
  value = 0
}: {
  label: string
  value?: number
}): React.ReactElement => (
  <div className={styles('field', { invalid: value <= 0 })}>
    <div>{label}</div>
    <div>${Number(value).toFixed(2)}</div>
  </div>
)

const WealthOutput = ({
  testID,
  year = 0,
  title
}: CommonProps & {
  year?: number
  title: string
}): React.ReactElement => {
  const totalValue = useRecoilValue(wealthSelector(year))

  return (
    <div>
      <Typography
        testID={`${testID}.assets`}
        text={title}
        variant={'subtitle'}
      />

      <Typography
        testID={`${testID}.wealth`}
        text={getPriceText(totalValue)}
        variant={'subtitle'}
      />
    </div>
  )
}

export { ValueOutput, WealthOutput }
