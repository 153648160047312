import { ProjectPeriod } from '@project/interfaces'

export const getRolePeriodText = (
  period?: ProjectPeriod | null
): string | null => {
  switch (period) {
    case ProjectPeriod.Contract:
      return 'Contract'

    case ProjectPeriod.Fulltime:
      return 'Fulltime'

    case ProjectPeriod.OpenSource:
      return 'Open source'

    case ProjectPeriod.PartTime:
      return 'Part-time'

    case ProjectPeriod.Personal:
      return 'Personal'

    default:
      return null
  }
}
