import * as checkbox from './checkbox.json'
import * as checked from './checked.json'
import * as dropdown from './dropdown.json'
import * as love from './love.json'
import * as radio from './radio.json'
import * as search from './search.json'
import * as signature from './signature.json'
import * as star from './star.json'

export const ICONS = {
  checked,
  checkbox,
  star,
  search,
  signature,
  love,
  radio,
  dropdown
}
