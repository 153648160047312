import React, { useEffect, useMemo, useState } from 'react'
import { Card, Input, Slider, Typography } from '@sigatoka/elements'
import { useRecoilState } from 'recoil'

import { profileState } from '@project/state'

const LeverageTool = ({ testID }: CommonProps): React.ReactElement => {
  const [assetValue, setAssetValue] = useState(1000000)
  const [capitalInvested, setCapitalInvested] = useState(100000)
  const [profile, setProfile] = useRecoilState(profileState)
  const [change, setChange] = useState(-10)

  const isCompleted = useMemo(() => assetValue > 0 && capitalInvested > 0, [
    assetValue,
    capitalInvested
  ])

  useEffect(() => setProfile({ leverage: assetValue / capitalInvested }), [
    assetValue,
    capitalInvested
  ])

  return (
    <div data-testid={testID}>
      <Input
        testID={`${testID}.asset`}
        label={'Asset value'}
        defaultValue={assetValue}
        onChange={({ currentTarget }) =>
          setAssetValue(Number(currentTarget.value))
        }
      />
      <Input
        testID={`${testID}.capital`}
        label={'Capital invested'}
        defaultValue={capitalInvested}
        onChange={({ currentTarget }) =>
          setCapitalInvested(Number(currentTarget.value))
        }
      />
      <Slider
        testID={`${testID}.change`}
        defaultValue={change}
        max={100}
        min={-100}
        onChange={(value) => setChange(value / 100)}
      />
      {isCompleted && (
        <Card testID={`${testID}.output`} padded rounded>
          <Typography
            testID={`${testID}.leverage`}
            variant={'title'}
            text={`Selling with ${profile.leverage}x leverage and a ${Number(
              change * 100
            ).toPrecision(2)}% change in market value will yield ${Math.round(
              Math.abs(change * profile.leverage * 100)
            )}% ${change < 0 ? 'loss' : 'profit'} on your investment`}
          />
        </Card>
      )}
    </div>
  )
}

export { LeverageTool }
