export enum NavigationConstants {
  Home = '/',
  Resume = 'resume',
  Services = 'services',
  Blog = 'blog',
  About = 'about',
  Contact = 'contact',
  Tools = 'tools*'
}

export type RouteConfiguration = { key: string; route: string; label: string }
