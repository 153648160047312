import { captureException } from '@sentry/react'
import axios, { AxiosInstance } from 'axios'

const config: Record<AppEnvironments, Record<AppRegions, App.Config>> = {
  DEV: {
    AU: {
      REST_API: 'https://api.dev.pierias.com'
    }
  },
  STAGE: {
    AU: {
      REST_API: 'https://api.dev.pierias.com'
    }
  },
  PROD: {
    AU: {
      REST_API: 'https://api.dev.pierias.com'
    }
  }
}

class ApiUrl extends URL {
  constructor(url: string | URL, region?: AppRegions) {
    super(url, 'https://api.dev.pierias.com')
  }
}

export type ContactFormDetails = {
  name: string
  mobile: string
  email: string
}

export class EntityApiService {
  private axios: AxiosInstance

  constructor() {
    this.axios = axios.create()
  }

  public saveDetails = ({
    name,
    mobile,
    email
  }: Partial<ContactFormDetails>) => {
    const url = new ApiUrl('/contact')

    return this.axios
      .post(url.toString(), {
        name,
        mobile,
        email
      })
      .then((response) => {
        if (response.data.errors) {
          throw response.data.errors[0].message
        }

        return response.data
      })
      .catch((error) => {
        captureException(error, { tags: { section: 'article' } })

        throw error
      })
  }
}
