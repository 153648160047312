import React from 'react'
import ReactDom from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { initReactI18next } from 'react-i18next'
import {
  BrowserRouter as Router,
  Route as Page,
  Routes
} from 'react-router-dom'
import i18n from 'i18next'
import { RecoilRoot } from 'recoil'

import { DeepLinkHandler } from '@project/hooks'
import { NavigationConstants } from '@project/interfaces'
import {
  AboutScene,
  BlogScene,
  ContactScene,
  ErrorScene,
  HomeScene,
  ResumeScene,
  ServiceScene,
  ToolScene
} from '@project/scenes'

import '@sigatoka/elements/lib/cjs/index.css'

import { loadState } from './loader'
import { initErrorReporting } from './reporting'
import { Themed } from './theme'
import en from './translations/en.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

// initErrorReporting()

/**
 * Top level application structure.
 *
 * Context, routers, stores and other application
 * level components should be mounted here.
 */
const App = (): React.JSX.Element => (
  <HelmetProvider>
    <RecoilRoot initializeState={loadState}>
      <Themed>
        <Router>
          <DeepLinkHandler>
            <Routes>
              <Page path={'contact'} Component={ContactScene} />
              <Page path={'services'} Component={ServiceScene} />
              <Page path={'resume'} Component={ResumeScene} />
              <Page path={'blog'} Component={BlogScene} />
              <Page path={'about'} Component={AboutScene} />
              <Page path={'tools*'} Component={ToolScene} />
              <Page Component={HomeScene} ErrorBoundary={ErrorScene} index />
            </Routes>
          </DeepLinkHandler>
        </Router>
      </Themed>
    </RecoilRoot>
  </HelmetProvider>
)

ReactDom.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
