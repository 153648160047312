export enum AssetType {
  Unknown = 0,
  Image,
  Video,
  File,
  Link
}

export interface ArticleAssetMedia extends ArticleAssetBase {
  type: AssetType.Image | AssetType.Video
  source: string
  alt?: string
  format?: 'jpeg' | 'gif' | 'png' | 'webp' | 'mov' | 'wmv'
}

export interface ArticleAssetLink extends ArticleAssetBase {
  type: AssetType.Link
  href: string
}

interface ArticleAssetBase {
  /**
   * Unique identifier fo the specific record
   */
  id: string
  /**
   * Unique key representing a shortened asset
   * identifier
   */
  key: string
  type?: AssetType
}
