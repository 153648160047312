import React from 'react'
import { Image, Link, Typography } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'
import style9 from 'style9'

import { latestArticlesSelector } from '@project/state'

const styles = style9.create({
  base: {
    fontFamily: 'Inter, Roboto, Arial',
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 'var(--size-500, 16px)'
  },
  content: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: 'var(--size-500, 16px)',
    gridRowGap: 'var(--size-900, 26px)'
  },
  article: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    gap: 'var(--size-100, 8px)'
  },
  image: {
    width: '100%'
  }
})

export const RecommendedArticles = (): React.ReactElement => {
  const { state: latestState, contents: latest } = useRecoilValueLoadable(
    latestArticlesSelector
  )

  return (
    <div className={styles('base')}>
      <Typography
        testID={`title`}
        variant={'label'}
        text={`Recommended`}
        weight={'heavy'}
        size={'small'}
        transform={'uppercase'}
      />

      <div className={styles('content')}>
        {latestState === 'loading' && 'Loading latest...'}
        {latestState === 'hasError' && <div>{latest.message}</div>}
        {latestState === 'hasValue' &&
          latest.map((article) => (
            <Link
              key={article.id}
              testID={`link`}
              href={`/blog/${article.id}`}
              className={styles('article')}
            >
              <Image
                testID={`image`}
                src={article.banner?.src}
                className={styles('image')}
              />
              <Typography
                testID={`title`}
                variant={'title'}
                text={article.title}
              />
              {article.subtitle && (
                <Typography
                  testID={`snippet`}
                  variant={'body'}
                  text={article.subtitle}
                />
              )}
            </Link>
          ))}
      </div>
    </div>
  )
}
