import React, { useMemo } from 'react'

import featureToggles from './features.json'

export type FeatureKey = keyof typeof featureToggles

type FeatureHook = {
  isEnabled: (key: FeatureKey) => boolean
  whenEnabled: (
    element: React.ReactElement,
    key: FeatureKey
  ) => React.ReactElement | null
}

export const useFeatures = (): FeatureHook => {
  const features = useMemo<Record<FeatureKey, boolean>>(() => featureToggles, [
    featureToggles
  ])

  const whenEnabled = (
    element: React.ReactElement,
    key: FeatureKey
  ): React.ReactElement | null => {
    if (features[key]) {
      return element
    }

    return null
  }

  const isEnabled = (key: FeatureKey): boolean => features[key] || false

  return {
    isEnabled,
    whenEnabled
  }
}
