/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React from 'react'
import { Typography } from '@sigatoka/elements'

import { CompanySlider } from '@project/components'

const content = [
  {
    label: `On The Surface`,
    items: [
      {
        title: 'Blockchain',
        body: `When it comes to the resume page, all of the text copy is sourced from a smart contract on the EOSIO blockchain. At least from a hosting point-of-view, the data source is decentralized. Access rights to manage the data come from digitally signing transactions against the actions exposed by the smart contract. All code and data can be publicly viewed on the EOSIO network, as with any smart contract.`
      },
      {
        title: `Dynamic CDN`,
        body:
          'Images are sourced from a custom CDN, served though CloudFront and backed by an Edge@Lambda responsible for dynamically resizing and reformatting images. So the client can request any size image, and specify from a range of image formats, and that image will by dynamical created and returned, if it doesn’t already exist in the blob storage.'
      },
      { title: 'Lottie' },
      {
        title: 'Atomic CSS',
        body: `If you’ve inspected the DOM, you might have seen allot of hashed class names which look allot like CSS Modules, yet they only containing a single attribute definition. Seems slightly crazy, but there’s logic behind the madness. This is Atomic CSS, Facebook’s yet to be released JSS engine. The concept is that styles are defined within each component with JSS, then at build time, all styles are lifted up, and any duplicate attribute definitions are split into a dedicated class. Finally, these class names are then pushed back down into their component references, and the components which declare the same attribute, share the same class names. At first this means we are creating classes at an accelerated rate. But eventually we begin to plateau as we reach the maximum number of CSS attributes, and instead of creating new classes, we end up just referencing them in more places. This means the size of our stylesheet has a theoretical limit and we update it less, therefore we end up caching it for longer and requesting it less!`
      },
      { title: 'Recoil' },
      { title: 'React Router' },
      { title: 'Suspense' },
      {
        title: 'Toggles',
        body: `Both feature and development toggles are used through the application to control the content displayed. Feature toggles are controlled in a backend dashboard and stored as a JSON blob through the CDN. These feature toggles are then fetched periodically from the server and populated through the render tree on page load.`
      }
    ]
  },
  {
    label: 'Behind the Scenes',
    items: [
      {
        title: 'Infrastructure as Code'
      },
      {
        title: 'Automated Pipeline'
      },
      {
        title: 'Unit Tests'
      },
      {
        title: 'Linting & Code Cleanup',
        body: `Sticking to the style of the project is easy for additional developers with strong linting and automated code clean up. Developers using VSCode benefit automatically on save with automatic code restyling, while other developers have their conformed to the project standards on commit or manually executing a cleanup script.`
      },
      { title: 'Component Library' }
    ]
  },
  {
    label: 'Path to the Future',
    description: 'With new technologies all around the corner...',
    items: [
      { title: 'Server Side Rendering' },
      { title: 'End-to-end Tests' },
      {
        title: 'Integration Tests',
        body: `Much the same as end-to-end test, an integration test checks the compiled application and it’s integration with other dependencies. With integration tests, we want to `
      },
      { title: 'Automated Documentation' },
      { title: 'GraphQL API Layer' },
      { title: 'Micro Frontend' }
    ]
  }
]

export const AboutScene = (): React.ReactElement => (
  <div style={{ display: 'grid', gap: '32px' }}>
    <Typography
      testID={`heading`}
      text={`While it might seem like a simple website with some rough UI design, typically of a software developer. This project showcases enterprise level setup. Encompasses all the buzz words and more!`}
    />

    <CompanySlider
      testID={`stack`}
      rows={[
        [
          'ReactJS',
          'AWS',
          'Lottie',
          'AtomicCSS',
          'React Router',
          'Recoil',
          'Jest',
          'EOSIO',
          'Storybook',
          'WebPack',
          'GraphQL',
          'CloudFront',
          'S3',
          'CloudFormation',
          'Route53'
        ],
        [
          'GraphQL',
          'CloudFront',
          'React Router',
          'Recoil',
          'Jest',
          'Lamington',
          'Storybook',
          'ReactJS',
          'AWS',
          'Lottie',
          'AtomicCSS',
          'WebPack'
        ]
      ]}
    />

    {content.map((section) => (
      <div key={section.label} style={{ display: 'grid', gap: '11px' }}>
        <Typography
          testID={`heading`}
          variant={'subheading'}
          transform={'uppercase'}
          weight={'heavy'}
          text={section.label}
        />
        {section.items.map((copy, idx) => (
          <div
            key={`${section.label}:${idx}`}
            style={{ display: 'grid', gap: '8px' }}
          >
            <Typography
              testID={`heading`}
              variant={'subtitle'}
              weight={'bold'}
              text={copy.title}
            />
            {copy.body && <Typography testID={`heading`} text={copy.body} />}
          </div>
        ))}
      </div>
    ))}
  </div>
)
