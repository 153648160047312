import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@sigatoka/elements'

import { Container } from '@project/components'

export const ErrorScene = ({
  testID = 'error'
}: Partial<CommonProps>): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Container testID={testID}>
      <Typography
        testID={`${testID}.title`}
        variant={'lead'}
        text={t('ErrorTitle', { defaultValue: `404` })}
      />
      <Typography
        testID={`${testID}.message`}
        variant={'body'}
        text={t('ErrorMessage', { defaultValue: `That's an error!` })}
      />
    </Container>
  )
}
