import React from 'react'
import { Thumbnail, ThumbnailSkeleton, Typography } from '@sigatoka/elements'
import { format } from 'date-fns'
import style9 from 'style9'

import { DisplayMode, ProjectHighlight } from '@project/interfaces'

import { Highlight, HighlightSkeleton } from '../highlight'

interface HighlightGroupProps extends CommonProps {
  startDate: number
  endDate: number
  title?: string
  subtitle?: string
  items: ProjectHighlight[]
  media?: { thumbnail?: string }
  mode?: DisplayMode
}

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(62px, min-content) auto',
    padding: 'var(--size-100)',
    '@media': {
      '(min-width: 768px)': {
        padding: 'var(--size-200)'
      }
    }
  },
  date: {
    padding: 'var(--size-200)'
  },
  items: {
    display: 'grid',
    gridAutoFlow: 'row'
  },
  skeleton: {
    justifySelf: 'min-content',
    userSelect: 'none',
    color: 'transparent',
    backgroundColor: 'var(--color-900)',
    borderTopLeftRadius: 'var(--size-100)',
    borderTopRightRadius: 'var(--size-100)',
    borderBottomLeftRadius: 'var(--size-100)',
    borderBottomRightRadius: 'var(--size-100)'
  }
})

export const HighlightGroup = ({
  testID,
  title,
  items = [],
  endDate,
  media,
  mode
}: HighlightGroupProps): React.ReactElement => (
  <div data-testid={testID} className={styles('base')}>
    <div data-testid={`${testID}.decorator`}>
      {media?.thumbnail ? (
        <div data-testid={`${testID}.media`}>
          <Thumbnail testID={`${testID}.image`} src={media.thumbnail} />
        </div>
      ) : (
        <Typography
          testID={`${testID}.year`}
          variant={'lead'}
          text={format(endDate, 'yy')}
          className={styles('date')}
        />
      )}
    </div>

    <div className={styles('items')}>
      {title && (
        <Typography
          testID={`${testID}.title`}
          variant={'subtitle'}
          text={title}
        />
      )}

      {items.map((item, idx) => (
        <Highlight
          key={`${item.title}-${idx}`}
          testID={`${testID}.detail`}
          title={item.title}
          startDate={item.dateStart}
          endDate={item.dateEnd}
          period={item.period}
          subtitle={item.subtitle ?? item.location?.label ?? undefined}
          description={item.description}
          tags={item.tags}
          expanded={item.expanded}
          mode={mode}
        />
      ))}
    </div>
  </div>
)

export const HighlightGroupSkeleton = ({
  testID,
  title
}: CommonProps & {
  title?: boolean
}): React.ReactElement => (
  <div data-testid={testID} className={styles('base')}>
    <div data-testid={`${testID}.decorator`}>
      <div>
        <ThumbnailSkeleton testID={`${testID}.media`} />
      </div>
    </div>

    <div className={styles('items')}>
      {title && (
        <Typography
          testID={`${testID}.title`}
          variant={'subtitle'}
          text={'Loading...'}
          className={styles('skeleton')}
        />
      )}

      <HighlightSkeleton testID={`${testID}.detail`} />
    </div>
  </div>
)
