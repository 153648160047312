import React from 'react'
import { Image, Typography } from '@sigatoka/elements'
import style9 from 'style9'

import { ContentCode, ContentLink } from '@project/components'
import { ArticleAsset, ArticleContent, ContentType } from '@project/interfaces'

interface ArticleProps extends CommonProps {
  banner?: { src: string }
  title: string
  subtitle?: string
  content?: ArticleContent[]
  feature?: ArticleAsset
}

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'row',
    maxWidth: 860,
    gap: 'var(--size-500)',
    padding: 'var(--size-500)'
  },
  header: { display: 'grid', gridAutoFlow: 'row' },
  feature: {
    maxWidth: '100%',
    borderTopLeftRadius: 'var(--size-600)',
    borderTopRightRadius: 'var(--size-600)',
    borderBottomLeftRadius: 'var(--size-600)',
    borderBottomRightRadius: 'var(--size-600)'
  },
  banner: {
    maxWidth: '150%',
    marginLeft: '-25%'
  }
})

export const Article = ({
  testID,
  title,
  subtitle,
  banner,
  content = []
}: ArticleProps): React.ReactElement => (
  <article data-testid={testID} className={styles('base')}>
    <Image
      testID={`${testID}.feature`}
      className={styles('feature')}
      src={banner?.src}
    />

    <div>
      <Typography
        testID={`${testID}.prefix`}
        variant={'label'}
        text={'Posted by:'}
        weight={'bold'}
        transform={'uppercase'}
      />

      <Typography
        testID={`${testID}.author`}
        text={'Mitch Pierias'}
        variant={'body'}
        weight={'bold'}
      />
    </div>

    <div data-testid={`${testID}.header`} className={styles('header')}>
      <Typography testID={`${testID}.title`} variant={'heading'} text={title} />

      {subtitle && (
        <Typography
          testID={`${testID}.subtitle`}
          variant={'subheading'}
          text={subtitle}
        />
      )}
    </div>

    {content.map(({ key, ...block }) => {
      if (ContentType.Heading === block.type) {
        return (
          <Typography
            testID={`${testID}.heading`}
            key={key}
            variant={'title'}
            text={block.text}
          />
        )
      }

      if (ContentType.Subheading === block.type) {
        return (
          <Typography
            testID={`${testID}.subheading`}
            key={key}
            variant={'subtitle'}
            text={block.text}
          />
        )
      }

      if (ContentType.Code === block.type) {
        return (
          <ContentCode
            testID={`${testID}.code`}
            key={key}
            content={block.source}
          />
        )
      }

      if (ContentType.Action === block.type) {
        return (
          <ContentLink
            testID={`${testID}.link`}
            key={key}
            heading={block.title}
            description={block.description}
            resource={block.resource}
          />
        )
      }

      return null
    })}
  </article>
)
