type CountryCode = 'AU' // | 'NZ' | 'JP' | 'DE' | 'BE'

type RuleKey = number

type RuleMultiplier = number

type RuleCondition = Record<RuleKey, RuleDeterminer>

type RuleDeterminer = 'AND' | 'OR' | 'NOT' | 'EQUALS' | 'GREATER' | 'LESS'

export type RuleBrackets = Record<RuleKey, RuleMultiplier>

const taxCategories = {
  organization: {
    tax: {
      0: 0.3
    }
  },
  individual: {
    tax: {
      18200: 0.19,
      45000: 0.325,
      120000: 0.37,
      180000: 0.45
    },
    hecs: {
      0: 0
    },
    super: {
      27500: 0.105
    }
  }
}

export type TaxCategories = Record<
  keyof typeof taxCategories,
  Record<'tax', RuleBrackets>
>

export const taxRegions: Record<CountryCode, TaxCategories> = {
  AU: taxCategories
}

export type TaxRegions = keyof typeof taxRegions
