import { ArticleData, AssetType, ContentType } from '@project/interfaces'

export const mockArticleBasic: ArticleData = {
  id: '7d048eee-3aa5-48e9-892f-adabc5095aba',
  version: '1',
  publisher: {
    id: '4136efb1-7253-4ed0-952a-3a6de461f4f9',
    name: 'Mitch Pierias'
  },
  author: {
    id: 'dd23bd3f-29fc-4066-b251-93a804ffd90c',
    name: 'Sigatoka Capital'
  },
  checksum: '',
  language: 'en',
  views: 1,
  shares: 0,
  votes: 0,
  timeCreated: 1595730462956,
  timeUpdated: 1595730472382,
  active: true,
  flagged: false,
  title: 'Title',
  subtitle: 'Subtitle',
  content: [
    {
      key: '6135bae5-4468-4573-a21d-b07101a9424c',
      type: ContentType.Text,
      text: 'Body of the paragraph'
    }
  ],
  assets: [],
  highlights: ['6135bae5-4468-4573-a21d-b07101a9424c']
}

export const mockArticle: ArticleData = {
  id: 'e9e92feb-ecb3-4a4d-b582-498f960a5250',
  version: '1',
  author: {
    id: '20924c9d-4836-441c-9f85-8445799ebca4',
    name: 'Unknown'
  },
  title: 'Mastering anything...',
  subtitle: 'Becoming a polymath',
  feature: 'a1c88795-54bf-44f2-9105-77299f23a0bc',
  checksum: 'Unset',
  language: 'en',
  views: 1,
  shares: 1,
  votes: 0,
  timeCreated: 1567861958760,
  timeUpdated: 1598441965716,
  active: true,
  flagged: false,
  content: [
    {
      key: '210aefbd-0952-457d-810a-9d086939dafd',
      text:
        'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
      type: ContentType.Text
    },
    {
      key: '5c0120ba-1db8-4049-a302-274cc0ba5f3e',
      title: 'View on Medium',
      description:
        'A collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…',
      link: 'https://github.com/MitchPierias/advanced-eos-examples',
      type: ContentType.Link,
      media: {
        id: '',
        key: '',
        type: AssetType.Image,
        source: 'https://avatars.githubusercontent.com/u/8856958?v=4',
        format: 'jpeg'
      }
    },
    {
      key: 'c5b434d4-669d-499d-94d4-e8c4dbc680a5',
      text: 'Mastering anything...',
      type: ContentType.Heading
    },
    {
      key: '210aefbd-0952-457d-810a-9d086939dafd',
      text:
        'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
      type: ContentType.Text
    },
    {
      key: '5c0120ba-1db8-4049-a302-274cc0ba5f3e',
      description:
        'A collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…',
      link: 'https://github.com/MitchPierias/advanced-eos-examples',
      type: ContentType.Link
    },
    {
      key: 'c5b434d4-669d-499d-94d4-e8c4dbc680a5',
      text: 'Mastering anything...',
      type: ContentType.Subheading
    },
    {
      key: '210aefbd-0952-457d-810a-9d086939dafd',
      text:
        'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
      type: ContentType.Text
    },
    {
      key: '5c0120ba-1db8-4049-a302-274cc0ba5f3e',
      description:
        'A collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…',
      link: 'https://github.com/MitchPierias/advanced-eos-examples',
      type: ContentType.Link
    },
    {
      key: 'c5b434d4-669d-499d-94d4-e8c4dbc680a5',
      text: 'Mastering anything...',
      type: ContentType.Subheading
    },
    {
      key: '210aefbd-0952-457d-810a-9d086939dafd',
      text:
        'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
      type: ContentType.Text
    },
    {
      key: '56df38b8-1a29-4254-8dfb-2815ca0ae2d7',
      type: ContentType.Action,
      title: 'Action Heading Text',
      resource: { label: 'View PDF', href: '/pdf' }
    },
    {
      key: '5c0120ba-1db8-4049-a302-274cc0ba5f3e',
      description:
        'A collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…',
      link: 'https://github.com/MitchPierias/advanced-eos-examples',
      type: ContentType.Link
    },
    {
      key: 'c5b434d4-669d-499d-94d4-e8c4dbc680a5',
      text: 'Mastering anything...',
      type: ContentType.Subheading
    },
    {
      key: '210aefbd-0952-457d-810a-9d086939dafd',
      text:
        'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
      type: ContentType.Text
    },
    {
      key: '5c0120ba-1db8-4049-a302-274cc0ba5f3e',
      description:
        'A collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…',
      link: 'https://github.com/MitchPierias/advanced-eos-examples',
      type: ContentType.Link
    }
  ],
  highlights: ['210aefbd-0952-457d-810a-9d086939dafd'],
  assets: [
    {
      id: 'a1c88795-54bf-44f2-9105-77299f23a0bc',
      key: 'a1c88795-54bf-44f2-9105-77299f23a0bc',
      type: AssetType.Link,
      href:
        'https://i.pinimg.com/originals/17/97/1b/17971b972fe10e10f2fe05dfb66c7878.jpg'
    }
  ]
}

// export const mockArticleData = {
//   id: '42bf878bee36',
//   version: '4f69931fb762',
//   publisher: 'cbff57364085',
//   homeCollectionId: '721b17443fd5',
//   title: 'Advanced EOS Series — Part 9 — Payable Actions',
//   detectedLanguage: 'en',
//   latestVersion: '4f69931fb762',
//   latestPublishedVersion: '4f69931fb762',
//   hasUnpublishedEdits: false,
//   latestRev: 851,
//   createdAt: 1567861958760,
//   updatedAt: 1598441965716,
//   acceptedAt: 0,
//   firstPublishedAt: 1568198941021,
//   latestPublishedAt: 1598441959559,
//   vote: false,
//   content: {
//     subtitle:
//       'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application…',
//     textModel: {
//       paragraphs: [
//         { name: '81a7', type: 3, text: 'Mastering anything...', markups: [] },
//         {
//           name: 'eaff',
//           type: 4,
//           text: '',
//           markups: [],
//           layout: 5,
//           metadata: { id: '1*S6NRjwS8UAP77fq8wXYeuA.jpeg', originalWidth: 1875, originalHeight: 750 }
//         },
//         {
//           name: '0a31',
//           type: 1,
//           text:
//             'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and working your way up. The full code for these examples can be found here on GitHub.',
//           markups: [
//             {
//               type: 3,
//               start: 267,
//               end: 287,
//               href: 'https://medium.com/coinmonks/advanced-eos-series-part-1-cryptographic-hashes-a251a8d371b8',
//               title: '',
//               rel: '',
//               anchorType: 0
//             },
//             {
//               type: 3,
//               start: 359,
//               end: 373,
//               href: 'https://github.com/MitchPierias/advanced-eos-examples/tree/master/09_Payable-Actions',
//               title: '',
//               rel: '',
//               anchorType: 0
//             }
//           ]
//         },
//         {
//           name: '94bd',
//           type: 14,
//           text:
//             'MitchPierias/advanced-eos-examples\nA collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…github.com',
//           markups: [
//             {
//               type: 3,
//               start: 0,
//               end: 156,
//               href: 'https://github.com/MitchPierias/advanced-eos-examples',
//               title: 'https://github.com/MitchPierias/advanced-eos-examples',
//               rel: '',
//               anchorType: 0
//             },
//             { type: 1, start: 0, end: 34 },
//             { type: 2, start: 35, end: 146 }
//           ],
//           mixtapeMetadata: {
//             mediaResourceId: 'aafa67f0499b86f067232cafa5efac85',
//             thumbnailImageId: '0*cgu8mmHUa9rM2JT5',
//             href: 'https://github.com/MitchPierias/advanced-eos-examples'
//           }
//         },
//         {
//           name: '5821',
//           type: 1,
//           text:
//             'By now you’ve managed to achieve all kinds of functionality with your smart contract, but your still missing a fundamental piece, receiving and transferring value…',
//           markups: []
//         },
//         { name: '36c9', type: 13, text: 'Let’s Get Paid', markups: [] },
//         {
//           name: 'd81e',
//           type: 1,
//           text:
//             'Things are different on the EOSIO network… payments are made to the eosio.token contract, and not directly to the contract’s action like on other blockchains. This means we need to listen for payments being made to our contract and provide the appropriate actions. Don’t worry, it’s simpler than it sounds! We are going to listen to the eosio.token::transfer notification sent by the eosio.token contract. Simply define our handler method by replacing our regular [[eosio::action] definition with [[eosio.token::on_notify(“eosio.token::transfer”)]]. This notification is sent when a token transfer occurs which involves your contract’s account. Let’s breakdown the complete transfer notification handler.',
//           markups: [
//             { type: 10, start: 68, end: 79 },
//             { type: 10, start: 337, end: 358 },
//             { type: 10, start: 384, end: 395 },
//             { type: 10, start: 464, end: 480 },
//             { type: 10, start: 497, end: 548 }
//           ]
//         },
//         {
//           name: 'd5e0',
//           type: 8,
//           text: '[[eosio::on_notify("eosio.token::transfer")]] void purchase(name user, name receiver, asset value, string memo) {',
//           markups: [{ type: 2, start: 46, end: 51 }]
//         },
//         {
//           name: '588c',
//           type: 8,
//           text:
//             '   if (receiver != get_self() || caller == get_self()) return;\n   \n   eosio::symbol token_symbol("SYM", 0);\n   eosio::check(value.amount > 0, "Insufficient value");\n   eosio::check(value.symbol == token_symbol, "Illegal asset symbol");\n   \n   wallet_table balances(get_self(), get_self().value);\n   auto wallet = balances.find(caller.value);\n   \n   if (wallet != balances.end()) {\n     balances.modify(wallet, get_self(), [&](auto &row) {\n       row.balance += value.amount;\n     });\n   } else {\n     balances.emplace(get_self(), [&](auto &row) {\n       row.account = caller;\n       row.balance = value.amount;\n     });\n   }\n}',
//           markups: [
//             { type: 2, start: 299, end: 303 },
//             { type: 2, start: 426, end: 430 },
//             { type: 2, start: 432, end: 435 },
//             { type: 2, start: 534, end: 538 },
//             { type: 2, start: 540, end: 543 }
//           ]
//         },
//         {
//           name: 'cea1',
//           type: 1,
//           text:
//             'You’ll notice our method looks very similar to a regular action, and that’s because it is an action! The difference being, this action is called by an INLINE_ACTION invoked by the eosio.token contract, and not directly by public accounts.',
//           markups: [{ type: 10, start: 151, end: 164 }, { type: 10, start: 180, end: 191 }]
//         },
//         { name: '52ef', type: 13, text: 'Breaking it down', markups: [] },
//         {
//           name: 'd4cd',
//           type: 1,
//           text:
//             'Let’s pull apart each line and look at what it’s doing. The method definition includes our subscription to the eosio.token::transfer notification. We need to handle all the arguments passed along with the notification.',
//           markups: [{ type: 10, start: 111, end: 132 }]
//         },
//         {
//           name: '04c9',
//           type: 8,
//           text:
//             '[[eosio::on_notify("eosio.token::transfer")]] void purchase(name user, name receiver, asset value, string memo) {\n  ... contract code ...\n}',
//           markups: [{ type: 2, start: 46, end: 51 }]
//         },
//         {
//           name: 'c876',
//           type: 1,
//           text:
//             'The first argument is the eosio::name value of the transfer sender, the second is the eosio::name value of the receiver. This should be your contracts account name when receiving funds, we will validate this in the next part. Finally we have the transfer value as an eosio::asset and a memo std::string.',
//           markups: [{ type: 10, start: 26, end: 37 }, { type: 10, start: 267, end: 279 }, { type: 10, start: 291, end: 302 }]
//         },
//         {
//           name: 'ec35',
//           type: 1,
//           text:
//             'Okay, now before we do anything, we first need to validate the sending and receiving participants are who we expected. It’s time to validate!',
//           markups: []
//         },
//         { name: 'c3c4', type: 8, text: 'if (receiver != get_self() || caller == get_self()) return;', markups: [] },
//         {
//           name: 'd66f',
//           type: 1,
//           text:
//             'This line is our sanity check, first making sure our contract get_self() is the intended receiver, and secondly, that our contract did not call this transfer on itself.',
//           markups: [{ type: 10, start: 62, end: 72 }]
//         },
//         {
//           name: '5903',
//           type: 8,
//           text:
//             'eosio::symbol token_symbol("SYM", 0);\neosio::check(value.amount > 0, "Insufficient value");\neosio::check(value.symbol == token_symbol, "Illegal asset symbol");',
//           markups: []
//         },
//         {
//           name: '146f',
//           type: 1,
//           text:
//             'We will continue validating the transferred asset by first defining the token symbol, then checking the sent value greater than zero, and it’s symbol matches that which we expect. You should do any other kind of validation you’d like here. You might want to check the current time is within your funding period, or that the caller is a registered and active user.',
//           markups: [{ type: 10, start: 78, end: 84 }, { type: 10, start: 143, end: 149 }]
//         },
//         {
//           name: '880e',
//           type: 1,
//           text: 'Now we’ve validated our arguments, we can perform the actions we’d like to undertake.',
//           markups: []
//         },
//         {
//           name: '44e5',
//           type: 8,
//           text: 'wallet_table balances(get_self(), get_self().value);\nauto wallet = balances.find(caller.value);',
//           markups: [{ type: 2, start: 53, end: 57 }]
//         },
//         {
//           name: '9664',
//           type: 1,
//           text:
//             'First we will allocate our wallet_table and search for our caller. Allot of this will now look similar to the previous sections on multi_index tables.',
//           markups: [{ type: 10, start: 27, end: 39 }, { type: 10, start: 59, end: 65 }, { type: 10, start: 131, end: 142 }]
//         },
//         {
//           name: 'c3bc',
//           type: 8,
//           text:
//             'if (wallet != balances.end()) {\n  balances.modify(wallet, get_self(), [&](auto &row) {\n    row.balance += value.amount;\n  });\n} else {\n  balances.emplace(get_self(), [&](auto &row) {\n    row.account = caller;\n    row.balance = value.amount;\n  });\n}',
//           markups: [
//             { type: 2, start: 74, end: 78 },
//             { type: 2, start: 80, end: 83 },
//             { type: 2, start: 170, end: 174 },
//             { type: 2, start: 176, end: 179 }
//           ]
//         },
//         {
//           name: '60df',
//           type: 1,
//           text:
//             'The last piece of code simply checks if the wallet was found (user’s transferred before), then either creates a new entry or updates the existing balance.',
//           markups: []
//         },
//         {
//           name: '9c3b',
//           type: 1,
//           text:
//             'And that’s it! If your deploying to a local blockchain, you’ll need to also deploy the eosio.token contract and then create and issue tokens before you can execute a transfer to your account. Please follow the official documentation if you need a step-by-step guide on interacting with the eosio.token contract.',
//           markups: [
//             { type: 10, start: 87, end: 98 },
//             { type: 10, start: 290, end: 301 },
//             {
//               type: 3,
//               start: 210,
//               end: 232,
//               href: 'https://developers.eos.io/eosio-home/docs/token-contract',
//               title: '',
//               rel: '',
//               anchorType: 0
//             }
//           ]
//         },
//         { name: 'ea8c', type: 13, text: 'Debugging', markups: [] },
//         {
//           name: 'ef12',
//           type: 1,
//           text:
//             'You might run into pesky errors working with transfers and assets. I stumbled into the symbol precision mismatch error, thrown when you attempt to issue or transfer assets using a different precision than was specified at creation. So if I created my asset with 10000.00 SYS, then I’d need to issue and transfer using two decimal precision like 12.00 SYS and not 12 SYS.',
//           markups: [
//             { type: 10, start: 87, end: 112 },
//             { type: 10, start: 262, end: 274 },
//             { type: 10, start: 345, end: 354 },
//             { type: 10, start: 363, end: 369 }
//           ]
//         },
//         { name: 'be02', type: 13, text: 'What’s next?', markups: [] },
//         {
//           name: '245b',
//           type: 1,
//           text:
//             'Make sure you click the follow button if you would like to be notified when I share more examples in this series. Alternatively follow my GitHub profile, and don’t forget to clap and star to show appreciation.',
//           markups: [{ type: 2, start: 24, end: 30 }]
//         },
//         {
//           name: 'c7e0',
//           type: 14,
//           text:
//             'MitchPierias/advanced-eos-examples\nA collection of Advanced EOS contract examples. All contracts have now been updated to conform with the latest…github.com',
//           markups: [
//             {
//               type: 3,
//               start: 0,
//               end: 156,
//               href: 'https://github.com/MitchPierias/advanced-eos-examples',
//               title: 'https://github.com/MitchPierias/advanced-eos-examples',
//               rel: '',
//               anchorType: 0
//             },
//             { type: 1, start: 0, end: 34 },
//             { type: 2, start: 35, end: 146 }
//           ],
//           mixtapeMetadata: {
//             mediaResourceId: 'aafa67f0499b86f067232cafa5efac85',
//             thumbnailImageId: '0*M--JhzsYQ73t-cbh',
//             href: 'https://github.com/MitchPierias/advanced-eos-examples'
//           }
//         },
//         {
//           name: 'b92f',
//           type: 7,
//           text: 'Get Best Software Deals Directly In Your Inbox',
//           markups: [
//             {
//               type: 3,
//               start: 0,
//               end: 46,
//               href: 'https://coincodecap.com/?utm_source=coinmonks',
//               title: '',
//               rel: 'noopener nofollow noopener noopener',
//               anchorType: 0
//             }
//           ]
//         },
//         {
//           name: '869b',
//           type: 4,
//           text: '',
//           markups: [],
//           layout: 1,
//           metadata: { id: '0*OJ-qb5G6i863msBB.png', originalWidth: 584, originalHeight: 484 },
//           href: 'https://coincodecap.com/?utm_source=coinmonks'
//         }
//       ],
//       sections: [{ name: '16d0', startIndex: 0 }]
//     },
//     postDisplay: { coverless: true }
//   },
//   virtuals: {
//     statusForCollection: 'APPROVED',
//     allowNotes: true,
//     previewImage: {
//       imageId: '1*S6NRjwS8UAP77fq8wXYeuA.jpeg',
//       filter: '',
//       backgroundSize: '',
//       originalWidth: 1875,
//       originalHeight: 750,
//       strategy: 'resample',
//       height: 0,
//       width: 0
//     },
//     wordCount: 859,
//     imageCount: 2,
//     readingTime: 3.6248427672955974,
//     subtitle:
//       'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application…',
//     userPostRelation: {
//       userId: 'cbff57364085',
//       postId: '42bf878bee36',
//       readAt: 1598441959639,
//       readLaterAddedAt: 0,
//       votedAt: 0,
//       collaboratorAddedAt: 0,
//       notesAddedAt: 0,
//       subscribedAt: 0,
//       lastReadSectionName: '',
//       lastReadVersionId: '',
//       lastReadAt: 0,
//       lastReadParagraphName: '',
//       lastReadPercentage: 0,
//       viewedAt: 1600753580414,
//       presentedCountInResponseManagement: 0,
//       clapCount: 0,
//       seriesUpdateNotifsOptedInAt: 0,
//       queuedAt: 0,
//       seriesFirstViewedAt: 0,
//       presentedCountInStream: 90,
//       seriesLastViewedAt: 0,
//       audioProgressSec: 0
//     },
//     publishedInCount: 1,
//     usersBySocialRecommends: [],
//     noIndex: false,
//     recommends: 6,
//     isBookmarked: false,
//     tags: [
//       {
//         slug: 'blockchain',
//         name: 'Blockchain',
//         postCount: 442271,
//         metadata: {
//           postCount: 442271,
//           coverImage: { id: '1*kuBBpOgPyiZAosNb_5j-FQ.jpeg', originalWidth: 2588, originalHeight: 1400, isFeatured: true }
//         },
//         type: 'Tag'
//       },
//       {
//         slug: 'eos',
//         name: 'Eos',
//         postCount: 10712,
//         metadata: {
//           postCount: 10712,
//           coverImage: { id: '1*v6uZwkTz9289atu_zKM4HA.png', originalWidth: 1500, originalHeight: 750, isFeatured: true }
//         },
//         type: 'Tag'
//       },
//       {
//         slug: 'eosio',
//         name: 'Eosio',
//         postCount: 3072,
//         metadata: {
//           postCount: 3072,
//           coverImage: { id: '1*KCZ1_RspL7cynEappo6LYA.png', originalWidth: 1920, originalHeight: 1080, isFeatured: true }
//         },
//         type: 'Tag'
//       },
//       {
//         slug: 'smart-contracts',
//         name: 'Smart Contracts',
//         postCount: 12605,
//         metadata: {
//           postCount: 12605,
//           coverImage: { id: '1*mg-TR5GKVkpQnTe-VoILFA.png', originalWidth: 4233, originalHeight: 2316, isFeatured: true }
//         },
//         type: 'Tag'
//       },
//       {
//         slug: 'blockchain-development',
//         name: 'Blockchain Development',
//         postCount: 4780,
//         metadata: {
//           postCount: 4780,
//           coverImage: { id: '1*wGkermb0gseK8knGZc7FvQ.png', originalWidth: 1500, originalHeight: 750, isFeatured: true }
//         },
//         type: 'Tag'
//       }
//     ],
//     socialRecommendsCount: 0,
//     responsesCreatedCount: 1,
//     links: {
//       entries: [
//         { url: 'https://coincodecap.com/?utm_source=coinmonks', alts: [], httpStatus: 200 },
//         { url: 'https://developers.eos.io/eosio-home/docs/token-contract', alts: [], httpStatus: 200 },
//         { url: 'https://github.com/MitchPierias/advanced-eos-examples/tree/master/09_Payable-Actions', alts: [], httpStatus: 200 },
//         { url: 'https://github.com/MitchPierias/advanced-eos-examples', alts: [], httpStatus: 200 },
//         {
//           url: 'https://medium.com/coinmonks/advanced-eos-series-part-1-cryptographic-hashes-a251a8d371b8',
//           alts: [{ type: 3, url: 'medium://p/a251a8d371b8' }, { type: 2, url: 'medium://p/a251a8d371b8' }],
//           httpStatus: 200
//         }
//       ],
//       version: '0.3',
//       generatedAt: 1598441960566
//     },
//     isLockedPreviewOnly: false,
//     metaDescription: '',
//     totalClapCount: 27,
//     sectionCount: 1,
//     readingList: 0,
//     topics: [
//       {
//         topicId: '9213b0063bcc',
//         slug: 'cryptocurrency',
//         createdAt: 1487665088618,
//         deletedAt: 0,
//         image: { id: '1*iOXaisZyDdgXEPpwHaFrRQ@2x.jpeg', originalWidth: 4739, originalHeight: 3159 },
//         name: 'Cryptocurrency',
//         description: 'An ode to the anti-banks.',
//         relatedTopics: [],
//         visibility: 1,
//         relatedTags: [],
//         relatedTopicIds: [],
//         seoTitle: 'Cryptocurrency News — Medium',
//         type: 'Topic'
//       },
//       {
//         topicId: 'c5fa1aeaf5a8',
//         slug: 'blockchain',
//         createdAt: 1534287618345,
//         deletedAt: 0,
//         image: { id: '1*4W0ZPOPO_dgx-B2sV6ROvg@2x.jpeg', originalWidth: 1920, originalHeight: 1344 },
//         name: 'Blockchain',
//         description: 'The distributed ledger domain.',
//         relatedTopics: [],
//         visibility: 1,
//         relatedTags: [],
//         relatedTopicIds: [],
//         seoTitle: 'Blockchain News and Articles — Medium',
//         type: 'Topic'
//       }
//     ]
//   },
//   coverless: true,
//   slug: 'advanced-eos-series-part-9-payable-actions',
//   translationSourcePostId: '',
//   translationSourceCreatorId: '',
//   isApprovedTranslation: false,
//   inResponseToPostId: '',
//   inResponseToRemovedAt: 0,
//   isTitleSynthesized: false,
//   allowResponses: true,
//   importedUrl: '',
//   importedPublishedAt: 0,
//   visibility: 0,
//   uniqueSlug: 'advanced-eos-series-part-9-payable-actions-42bf878bee36',
//   previewContent: {
//     textModel: {
//       paragraphs: [
//         { name: '81a7', type: 3, text: 'Mastering anything...', markups: [], alignment: 1 },
//         {
//           name: 'eaff',
//           type: 4,
//           text: '',
//           markups: [],
//           layout: 10,
//           metadata: { id: '1*S6NRjwS8UAP77fq8wXYeuA.jpeg', originalWidth: 1875, originalHeight: 750 }
//         }
//       ],
//       sections: [{ startIndex: 0 }]
//     },
//     isFullContent: false,
//     subtitle:
//       'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application…'
//   },
//   license: 0,
//   inResponseToMediaResourceId: '',
//   canonicalUrl: 'https://medium.com/coinmonks/advanced-eos-series-part-9-payable-actions-42bf878bee36',
//   approvedHomeCollectionId: '721b17443fd5',
//   newsletterId: '',
//   webCanonicalUrl: 'https://medium.com/coinmonks/advanced-eos-series-part-9-payable-actions-42bf878bee36',
//   mediumUrl: 'https://medium.com/coinmonks/advanced-eos-series-part-9-payable-actions-42bf878bee36',
//   migrationId: '',
//   notifyFollowers: true,
//   notifyTwitter: false,
//   notifyFacebook: false,
//   responseHiddenOnParentPostAt: 0,
//   isSeries: false,
//   isSubscriptionLocked: false,
//   seriesLastAppendedAt: 0,
//   audioVersionDurationSec: 0,
//   sequenceId: '',
//   isEligibleForRevenue: false,
//   isBlockedFromHightower: false,
//   deletedAt: 0,
//   lockedPostSource: 0,
//   hightowerMinimumGuaranteeStartsAt: 0,
//   hightowerMinimumGuaranteeEndsAt: 0,
//   featureLockRequestAcceptedAt: 0,
//   mongerRequestType: 1,
//   layerCake: 4,
//   socialTitle: '',
//   socialDek: '',
//   editorialPreviewTitle: '',
//   editorialPreviewDek: '',
//   curationEligibleAt: 1568198940376,
//   isProxyPost: false,
//   proxyPostFaviconUrl: '',
//   proxyPostProviderName: '',
//   proxyPostType: 0,
//   isSuspended: false,
//   isLimitedState: false,
//   seoTitle: '',
//   previewContent2: {
//     textModel: {
//       paragraphs: [
//         { name: '81a7', type: 3, text: 'Mastering anything...', markups: [] },
//         {
//           name: 'eaff',
//           type: 4,
//           text: '',
//           markups: [],
//           layout: 5,
//           metadata: { id: '1*S6NRjwS8UAP77fq8wXYeuA.jpeg', originalWidth: 1875, originalHeight: 750 }
//         },
//         {
//           name: '0a31',
//           type: 1,
//           text:
//             'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application developer on the EOSIO blockchain. Each post is ordered by difficulty, so if you’ve just stumbled in here and feel lost, I’d recommend starting with Part 1 and…',
//           markups: [
//             {
//               type: 3,
//               start: 267,
//               end: 287,
//               href: 'https://medium.com/coinmonks/advanced-eos-series-part-1-cryptographic-hashes-a251a8d371b8',
//               title: '',
//               rel: '',
//               anchorType: 0
//             },
//             {
//               type: 3,
//               start: 359,
//               end: 373,
//               href: 'https://github.com/MitchPierias/advanced-eos-examples/tree/master/09_Payable-Actions',
//               title: '',
//               rel: '',
//               anchorType: 0
//             }
//           ]
//         }
//       ],
//       sections: [{ name: '16d0', startIndex: 0 }]
//     },
//     isFullContent: false,
//     subtitle:
//       'The purpose of this series is to bring together the missing pieces you’ll need to complete your skills as a distributed application…'
//   },
//   cardType: 0,
//   isDistributionAlertDismissed: false,
//   isShortform: false,
//   shortformType: 0,
//   responsesLocked: false,
//   isLockedResponse: false,
//   type: 'Post'
// }

const mockDynamoArticle2 = {
  ArticleId: '3f64f28b-d00e-48a6-9813-ab9d21cbdf79',
  EntityId: 'c75043b5-73fa-41d8-8b67-8957f84eb153',
  Language: 'en',
  Content: [
    {
      DateCreated: '2021-05-01T12: 06: 41Z',
      Type: 1,
      Text:
        'Implementing a dynamic CDN with Lambda@Edge to resize images on the fly.',
      Assets: [],
      Key: '1ea436f7-2dcd-48dd-bbe5-2777398cd746'
    },
    {
      DateCreated: '2021-05-01T12: 06: 41Z',
      Type: 1,
      Text: 'CloudFront lets us server static assets with efficient caching.',
      Assets: [],
      DateUpdated: '2021-05-01T12: 07: 20Z',
      Key: 'ce7068ec-5925-4574-b693-45217e9a876f'
    },
    {
      DateCreated: '2021-05-01T12: 06: 41Z',
      Type: 1,
      Text:
        'Responses from Lamda@Edge functions are limited to 1MB, which includes headers. Therefore, we need to be conscious of the image sizes we allow and limit user input to prevent excessively large images.',
      Assets: [],
      Key: 'edf292ee-174a-4ffb-a091-702f3852da6d'
    }
  ],
  Topics: [
    {
      RelatedTopics: [],
      Active: true,
      Type: 'Topic',
      Description: 'An ode to the anti-banks.',
      DateDeleted: null,
      RelatedTags: [],
      DateCreated: 1487665088618,
      SeoTitle: 'Cryptocurrency News',
      TopicId: '4c91fb196232',
      Slug: 'cryptocurrency',
      Image: {
        Width: 4739,
        Height: 3159,
        Name: '4c91fb196232@2x.jpeg'
      },
      Name: 'Cryptocurrency'
    },
    {
      RelatedTopics: [],
      Active: true,
      Type: 'Topic',
      Description: 'The distributed ledger domain.',
      DateDeleted: null,
      RelatedTags: [],
      DateCreated: 1534287618345,
      SeoTitle: 'Blockchain News and Articles',
      TopicId: 'e386e13eee79',
      Slug: 'blockchain',
      Image: {
        Width: 1920,
        Height: 1344,
        Name: 'e386e13eee79@2x.jpeg'
      },
      Name: 'Blockchain'
    }
  ],
  Votes: 0,
  AuthorId: '1ea436f7-2dcd-48dd-bbe5-2777398cd746',
  Views: 1,
  DateUpdated: '2021-05-01T12: 07: 20Z',
  Title: 'Dynamic CDN with Edge Functions',
  Flagged: false,
  PublisherId: '1ea436f7-2dcd-48dd-bbe5-2777398cd746',
  Subtitle: 'Serving dynamically formatted images on the fly',
  Version: '2',
  Shares: 0,
  DateCreated: '2021-05-01T12: 06: 41Z',
  Active: true,
  Checksum: 'db370c996e4f4cf7cf5e64b47f8c3c2e108391fe'
}

const mockDynamoArticle = {
  ArticleId: 'd3b47432-0548-47c6-a726-9c97eb2f65a8',
  EntityId: 'c75043b5-73fa-41d8-8b67-8957f84eb153',
  Categories: ['Philosophy'],
  Language: 'en',
  Content: [
    {
      DateCreated: '2021-06-26T23: 42: 20Z',
      Type: 1,
      Text: 'Practicing Optimistic Nihilism',
      Assets: [],
      DateUpdated: '2021-06-26T23: 44: 20Z',
      Key: '295847e2-0fa3-4987-ae2f-b7460a0a6c6f'
    },
    {
      DateCreated: '2021-06-26T23: 42: 20Z',
      Type: 1,
      Text: 'In the Face of Chrisis',
      Assets: [],
      Key: 'c09af0c0-28d6-4cc7-be7a-dc16df4b481b'
    }
  ],
  Topics: [
    {
      Active: true,
      RelatedTopics: ['Philospohy', 'Mindfullness'],
      Type: 'Topic',
      Description: 'Food for thought',
      DateDeleted: null,
      RelatedTags: ['philosophy', 'optimistic nihilism', 'well-being'],
      DateCreated: 1487667878618,
      SeoTitle: 'Optimistic Nihilism',
      TopicId: '120c9f30f0f6',
      Slug: 'optimistic-nihilism',
      Name: 'Philosophy'
    }
  ],
  Votes: 0,
  AuthorId: '1ea436f7-2dcd-48dd-bbe5-2777398cd746',
  Views: 0,
  DateUpdated: '2021-06-26T23: 42: 20Z',
  Title: 'Practicing Optimistic Nihilism',
  Flagged: false,
  PublisherId: '1ea436f7-2dcd-48dd-bbe5-2777398cd746',
  Subtitle: 'In the Face of Chrisis',
  Version: '1',
  Shares: 0,
  DateCreated: '2021-06-26T23: 38: 41Z',
  Active: false,
  Checksum: ''
}
