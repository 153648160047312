/* eslint-disable max-lines, max-lines-per-function */
import React from 'react'
import { Route as Page, Routes } from 'react-router-dom'
import { Link } from '@sigatoka/elements'

import { Container } from '@project/components'

import { BudgetTool } from './tools/budget-tool'
import { LeverageTool } from './tools/leverage-tool'
import { PositionSize } from './tools/sizing-tool'

const Menu = (): React.ReactElement => (
  <div>
    <Link testID={`link`} text={`Budget`} href={'/tools/budget'} />
    <Link testID={`link`} text={`Leverage`} href={'/tools/leverage'} />
    <Link testID={`link`} text={`Position Sizing`} href={'/tools/sizing'} />
  </div>
)

export const ToolScene = ({ testID }: CommonProps): React.ReactElement => (
  <Container testID={testID}>
    <Routes>
      <Page
        path={'budget'}
        element={<BudgetTool testID={`${testID}.budget`} />}
      />
      <Page
        path={'leverage'}
        element={<LeverageTool testID={`${testID}.leverage`} />}
      />
      <Page
        path={'sizing'}
        element={<PositionSize testID={`${testID}.sizing`} />}
      />
      <Page Component={Menu} index />
    </Routes>
  </Container>
)
