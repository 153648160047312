/* eslint-disable max-lines-per-function, no-magic-numbers, max-len */
import React, { useCallback } from 'react'
import { Button, Typography } from '@sigatoka/elements'
import { useRecoilState } from 'recoil'
import style9 from 'style9'
import { v4 } from 'uuid'

import { holdingsState } from '../../../state/financial/expense.state'
import { AssetSection } from '../components/asset-section'
import { getPriceText } from '../components/expense-row'
import { WealthOutput } from './../tool-components'

const styles = style9.create({
  base: {},
  field: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  card: {
    padding: 'var(--size-100)',
    border: '1px solid var(--color-800)',
    borderRadius: 'var(--size-100)'
  },
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gridAutoColumns: 'auto'
  },
  horizontal: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 'var(--size-100)'
  }
})

const BudgetTool = ({ testID }: CommonProps): React.ReactElement => {
  const [holdings, setHoldings] = useRecoilState(holdingsState)

  const createHolding = useCallback(
    () => setHoldings((state) => [...state, v4()]),
    []
  )

  return (
    <div style={{ display: 'grid', gap: 'var(--size-500)' }}>
      <div className={styles('horizontal')}>
        <Typography
          testID={`${testID}.holdings`}
          text={`Holdings`}
          variant={'title'}
        />
        <Button
          testID={`${testID}.create`}
          text={'add'}
          onClick={createHolding}
        />
      </div>

      {holdings.map((id) => (
        <div key={id}>
          <AssetSection testID={`${testID}.holding`} key={id} id={id} />
        </div>
      ))}

      <div className={styles('field')}>
        <Typography
          testID={`${testID}.equity`}
          text={`Equity`}
          variant={'title'}
        />
        <Typography
          testID={`${testID}.timeline`}
          text={getPriceText(0)}
          variant={'title'}
        />
      </div>

      <div
        style={{
          overflowX: 'scroll'
        }}
      >
        <Typography
          testID={`${testID}.wealth`}
          text={`Forecast`}
          variant={'title'}
        />

        <WealthOutput testID={`${testID}.balance`} title={`Current`} />
      </div>

      <div className={styles('field')}>
        <Typography
          testID={`${testID}.timeline`}
          text={`Runway`}
          variant={'title'}
        />
        <Typography
          testID={`${testID}.timeline`}
          text={getPriceText(0)}
          variant={'title'}
        />
      </div>

      <div>
        <Typography
          testID={`${testID}.timeline`}
          text={`Timeline`}
          variant={'title'}
        />
      </div>

      <Typography
        testID={`${testID}.timeline`}
        text={`All projections are estimates calculated on a recurring yearly scale. They are intended to serve mearly as a guide and should not be taken as accurate. Many macro economic factors and micro spending habits can have a large influence on the final wealth achieved over time. It's your responsibility to track and manage the outcome of your own wealth.`}
        variant={'small'}
        align={'center'}
      />
    </div>
  )
}

export { BudgetTool }
