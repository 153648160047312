import React from 'react'
import { Typography } from '@sigatoka/elements'
import style9 from 'style9'

interface CompanySliderProps extends CommonProps {
  rows: string[][]
}

const styles = style9.create({
  base: {
    width: '100%',
    display: 'block',
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  row: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block'
  },
  item: {
    opacity: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'inline-block',
    cursor: 'default'
  },
  slide: {
    animationDuration: '80s',
    animationTimingFunction: 'linear',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
    animationName: style9.keyframes({
      from: {
        transform: 'translate3d(0,0,0)'
      },
      to: {
        transform: 'translate3d(-50%,0,0)'
      }
    })
  }
})

export const CompanySlider = ({
  testID,
  rows = []
}: CompanySliderProps): React.ReactElement => (
  <div data-testid={testID} className={styles('base')}>
    {rows.map((items, idx) => (
      <div
        data-testid={`${testID}.row`}
        key={idx}
        className={styles('row', 'slide')}
      >
        {items.map((item) => (
          <Typography
            testID={`${testID}.item`}
            key={item}
            className={styles('item')}
            text={item}
          />
        ))}
      </div>
    ))}
  </div>
)
