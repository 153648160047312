import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import style9 from 'style9'

import { Footer, Header } from '@project/components'
import { useFeatures } from '@project/hooks'

interface LayoutContainerProps extends CommonProps {
  children?: CommonChildren
}

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: 'auto 1fr auto',
    justifyItems: 'center',
    gap: 'var(--size-900)',
    width: '100%',
    height: '100%',
    minHeight: '100vh'
  },
  content: {
    width: '100%',
    display: 'grid',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollBehavior: 'smooth'
  }
})

export const Layout = ({
  testID,
  children
}: LayoutContainerProps): React.ReactElement => {
  const location = useLocation()
  const { isEnabled } = useFeatures()

  const isPrintMode = useMemo(() => {
    const query = new URLSearchParams(location.search)

    return query.get('view') === 'print'
  }, [location])

  return (
    <div data-testid={testID} className={styles('base')}>
      {isPrintMode ? null : <Header testID={`${testID}.header`} />}

      <main className={styles('content')}>{children}</main>

      {isEnabled('footer') && !isPrintMode && (
        <Footer testID={`${testID}.footer`} />
      )}
    </div>
  )
}
