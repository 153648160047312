import { atom } from 'recoil'

import { ExpenseFrequency } from '@project/scenes/tool-scene/tool-scene.interface'

export const profileState = atom({
  key: 'profileState',
  default: {
    leverage: 10,
    riskRatio: 0.001,
    balance: 100000,
    averageProfitPercentage: 0.08
  }
})

export const preferredFrequency = atom<ExpenseFrequency>({
  key: 'preferredFrequency',
  default: ExpenseFrequency.Weekly
})
