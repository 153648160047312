import { CashFlow } from '@project/state/financial/expense.state'

export type BalanceSheet = {
  label: string
  incoming: (BalanceSheet | CashFlow)[]
  holdings: (BalanceSheet | HoldingData)[]
  outgoing: ExpenseData[]
}

type HoldingData = {
  label: string
  value: number
  balance?: number
  frequency?: ExpenseFrequency
  interestRate?: number
  dateStart?: number
  dateEnd?: number | null
}

export type Entity = {
  label: string
  /**
   * Current goal value
   */
  value: number
  /**
   * Target value for the goal
   */
  target?: number
  type?: ExpenseType
  /**
   * Utilization is the amount owing versus
   * the paid. This is obvious in a loan for
   * instance, but could also relate to the
   * shares in a business if you've put
   * capital in and are now in a gain or
   * loss.
   */
  utilization?: number
  category?: ExpenseCategory
  /**
   * Effort rating between zero and
   * one to weight the plausibility of
   * projections.
   */
  effort?: number
}

export enum ExpenseFrequency {
  Hourly = 'H',
  Daily = 'D',
  Weekly = 'W',
  Fortnightly = 'F',
  Monthly = 'M',
  Quarterly = 'Q',
  Yearly = 'Y'
}

export enum SubscriptionType {
  Entertainment = 'entertainment',
  Communication = 'communication',
  Productivity = 'productivity'
}

export enum ExpenseType {
  Unknown = 'unknown',
  Salary = 'salary',
  /**
   * Recurring billing items which are
   * also a living requirement.
   */
  Service = 'service',
  Subscription = 'subscription',
  Billing = 'billing',
  Credit = 'credit'
}

export enum ExpenseCategory {
  Health = 'health',
  Utility = 'utilities',
  Living = 'living',
  Transport = 'transport',
  Food = 'food',
  Lifestyle = 'lifestyle',
  Social = 'social',
  Entertainment = 'entertainment',
  Donation = 'donation',
  Luxury = 'luxury',
  Maintenance = 'maintenance'
}

export type ExpenseData = {
  label: string
  value: number
  balance?: number
  interestRate?: number
  dateStart?: number
  dateEnd?: number
  dateCreated?: number
  dateModified?: number | null
  frequency?: ExpenseFrequency
  type?: ExpenseType
  category?: ExpenseCategory
  history?: number[]
  disabled: boolean
}

export type Goal = {
  label: string
  /**
   * Current goal value
   */
  value: number
  /**
   * Target value for the goal
   */
  target: number
  type?: ExpenseType
  category?: ExpenseCategory
}
