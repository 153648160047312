import React from 'react'

interface ContentLinkProps extends CommonProps {
  heading: string
  description: string | undefined
  resource: CommonResource
}

export const ContentLink = ({ testID, content }: ContentLinkProps) => (
  <div data-testid={testID}>{content}</div>
)
