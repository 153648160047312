import { atom, atomFamily, selector } from 'recoil'

import { GeoLocation } from '@project/interfaces'

const locationIndexes = atom({
  key: 'locationIndexes',
  default: []
})

const locationState = atomFamily<GeoLocation, GeoLocation['id']>({
  key: 'locationState',
  default: (key) => ({
    id: key,
    label: null,
    city: null,
    country: null
  })
})

const allLocations = selector<GeoLocation[] | null>({
  key: 'allLocations',
  get: ({ get }) => {
    // Get the configuration of our current table
    const locations = get(locationIndexes)

    return locations.map((key) => get(locationState(key)))
  }
})

export { allLocations, locationIndexes, locationState }
