import React from 'react'
import classnames from 'classnames'
import style9 from 'style9'

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(auto, 800px)',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  padded: {
    padding: 'var(--size-600)'
  }
})

export const Container = ({
  testID,
  children,
  className
}: React.PropsWithChildren<
  CommonProps & { className?: string }
>): React.ReactElement => (
  <div data-testid={testID} className={styles('base')}>
    <div className={classnames(styles('padded'), className)}>{children}</div>
  </div>
)
