import { ProjectType } from '@project/interfaces'

interface Highlight {
  id: number
  type: ProjectType
  title: string
  subtitle?: string
  description?: string | string[]
}

type EducationHighlight = Highlight & {
  type: ProjectType.Education
}

export const mockHighlightEducation: EducationHighlight = {
  id: 0,
  type: ProjectType.Education,
  title: 'Fundamentals of Neuroscience'
}

export const mockHighlightEducationSubtitle: EducationHighlight = {
  ...mockHighlightEducation,
  id: 1,
  subtitle: 'X-Series Short Course Program'
}

export const mockHighlightEducationDescription: EducationHighlight = {
  ...mockHighlightEducation,
  id: 2,
  description: [
    'The Electrical Properties of the Neuron',
    'Neurons and Networks',
    'The Brain'
  ]
}

export const mockHighlightEducationComplete: EducationHighlight = {
  ...mockHighlightEducation,
  ...mockHighlightEducationSubtitle,
  ...mockHighlightEducationDescription,
  id: 3
}
