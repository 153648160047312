import { selectorFamily } from 'recoil'

import { Article, fetchArticle } from '@project/business'

export const articleSelector = selectorFamily<
  Article | null,
  string | undefined
>({
  key: 'article-selector',
  get: (articleId) => async ({ get }): Promise<Article | null> => {
    if (articleId) {
      return await fetchArticle(articleId)
    }

    return null
  }
})
