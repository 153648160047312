import React, { useCallback, useState } from 'react'
import { ButtonInput, Typography } from '@sigatoka/elements'
import style9 from 'style9'

type SubscribeBannerProps = CommonProps

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 'var(--size-400, 11px)',
    alignItems: 'center',
    padding: 'var(--size-100, 6px)'
  },
  copy: {
    display: 'grid',
    gap: 'var(--size-100, 8px)'
  }
})

export const SubscribeBanner = ({
  testID
}: SubscribeBannerProps): React.ReactElement => {
  const [email, setEmail] = useState<string | null>(null)

  const subscribeEmail = useCallback(() => {
    if (!email) {
      alert('No email')

      return
    }
  }, [email])

  return (
    <div data-testid={testID} className={styles('base')}>
      <div data-testid={`${testID}.copy`} className={styles('copy')}>
        <Typography
          testID={`${testID}.label`}
          variant={'label'}
          transform={'uppercase'}
          weight={'heavy'}
          text={'Keep up to date'}
        />
        <Typography
          testID={`${testID}.message`}
          variant={'body'}
          text={'Get the latest posts tailored for you'}
        />
      </div>

      <ButtonInput
        testID={`${testID}.subscribe`}
        label={'Email'}
        placeholder={`email@example.com`}
        onChange={(event) => setEmail(event.currentTarget.value)}
        onSubmit={subscribeEmail}
      />
    </div>
  )
}
