import { atomFamily } from 'recoil'

import { Organization } from '@project/interfaces'

const orgState = atomFamily({
  key: 'orgState',
  default: (key: string): Organization => ({
    id: key,
    name: null
  })
})

export { orgState }
