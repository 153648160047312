/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { SearchInput } from '@sigatoka/elements'
import classnames from 'classnames'
import style9 from 'style9'

import { NavigationConstants } from '@project/interfaces'
import { useDevToggles } from '@project/state'

import { Navigation } from '../navigation'

interface HeaderProps extends CommonProps {
  /**
   * Extends the class name with additional
   * styles.
   */
  className?: string
}

const styles = style9.create({
  base: {
    width: '100vw',
    maxWidth: '100vw',
    position: 'sticky',
    top: '0px',
    backdropFilter: 'blur(var(--size-100, 10px))',
    backgroundColor: 'transparent',
    overflowY: 'hidden',
    overflowX: 'hidden',
    zIndex: 1,
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: 'var(--color-900)',
      opacity: 0.3,
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      '@supports': {
        'not(backdropFilter: blur)': {
          opacity: 0.9
        }
      }
    }
  },
  container: {
    maxWidth: 860,
    margin: 'auto',
    padding: 'var(--size-600)',
    display: 'grid',
    alignItems: 'center',
    gridAutoColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    justifyItems: 'space-between'
  },
  logo: {
    fill: 'var(--color-400)',
    zIndex: 2,
    stroke: 'var(--color-400)',
    width: '100px',
    '@media': {
      '(min-width: 768px)': {
        width: '160px'
      }
    }
  }
})

/**
 * Renders a header element containing the individual's
 * name, current profession, location and resource links
 */
export const Header = ({
  testID,
  className
}: HeaderProps): React.ReactElement => {
  const { isEnabled } = useDevToggles()

  return (
    <header
      data-testid={testID}
      className={classnames(styles('base'), className)}
    >
      <div className={styles('container')}>
        <img
          data-testid={`${testID}.logo`}
          alt={'pierias'}
          src={`https://cdn.dev.pierias.com/svg/signature-emblem.png`}
          className={styles('logo')}
        />

        {isEnabled('search') && (
          <SearchInput
            testID={`${testID}.search`}
            icon={'search'}
            placeholder={'Search...'}
            condensed
          />
        )}

        <Navigation
          testID={`${testID}.navigation`}
          links={[
            {
              key: 'home',
              to: NavigationConstants.Home,
              label: 'Me'
            },
            {
              key: 'resume',
              to: `/${NavigationConstants.Resume}`,
              label: 'Projects'
            },
            {
              key: 'contact',
              to: `/${NavigationConstants.Contact}`,
              label: 'Contact'
            }
          ]}
        />
      </div>
    </header>
  )
}
