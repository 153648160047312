import { RuleBrackets } from '../constants/tax-rates'

export const findMatchingRule = (
  input: number,
  brackets: RuleBrackets
): number => {
  const key = Object.keys(brackets)
    .sort((a, b) => Number(b) - Number(a))
    .find((threshold) => input > Number(threshold))

  return key ? brackets[key] : 0
}

/**
 * For instance; In Australia (AU) we need rules
 * for individuals to determine tax, super, and
 * other government fees.
 */
export class Rules {
  public key: 'tax' | 'super' = 'tax'
  public label = 'Tax'
  private _brackets: RuleBrackets = {}

  constructor(brackets: RuleBrackets) {
    this._brackets = brackets
  }

  public findBracket = (input: number): number => {
    const key = Object.keys(this._brackets)
      .sort((a, b) => Number(b) - Number(a))
      .find((threshold) => input > Number(threshold))

    return key ? this._brackets[key] : 0
  }
}
