import { GeoLocation } from './location.interface'
import { Organization } from './organization.interface'

export type Project = {
  id: string
  active: boolean
  type: ProjectType
  period: ProjectPeriod
  title: string | null
  subtitle?: string | null
  organization: string | null
  expanded: boolean
  description: string[]
  link?: string | null
  dateStart: number | null
  dateEnd?: number | null
  tags: number[]
  location: string | null
}

export type ProjectHighlight = {
  id: string
  type: ProjectType
  period: ProjectPeriod
  title: string
  subtitle?: string | null
  organization: Organization | null
  expanded: boolean
  description: string[]
  link?: string | null
  dateStart: number
  dateEnd?: number
  tags: number[]
  location: GeoLocation | null
}

export type ProjectState = Project & {
  description: string | string[] | null
  location: string | null
  organization: string | null
}

export type ProjectGroup = {
  id: string
  organization: Organization
  type: ProjectType
  media: CommonMedia
  title: string
  dateStart?: number | null
  dateEnd?: number | null
  projects: ProjectHighlight[]
  expanded: boolean
}

export type ProjectCategory = {
  key: string
  title: string
  projects: Project['id'][]
}

export enum ProjectType {
  Occupation = 0,
  Education,
  Award,
  Community,
  Publication,
  Project
}

export enum ProjectPeriod {
  Fulltime = 0,
  PartTime,
  Contract,
  Owner,
  OpenSource,
  Personal
}

export type ProjectSkill = {
  key: number
  label: string | null
}
