import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@sigatoka/elements'
import style9 from 'style9'

import { FeatureKey, useFeatures } from '@project/hooks'
import { NavigationConstants } from '@project/interfaces'

interface NavigationLink {
  key: FeatureKey
  to: NavigationConstants
  label: string
}

interface NavigationProps extends CommonProps {
  links?: NavigationLink[]
}

const styles = style9.create({
  base: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    justifyItems: 'center',
    zIndex: 1
  },
  link: {
    margin: '6px 10px',
    fontWeight: 600,
    textDecorationLine: 'none',
    color: 'var(--color-200)',
    ':hover': {
      color: 'var(--accent-500)'
    },
    ':visited': {
      color: 'inherit'
    }
  },
  selected: {
    color: 'var(--accent-500)'
  }
})

const MIN_LINKS = 2

export const Navigation = ({
  testID,
  links = []
}: NavigationProps): React.ReactElement | null => {
  const { isEnabled } = useFeatures()

  const filteredLinks = useMemo(
    () => links.filter(({ key }) => isEnabled(key)),
    [links, isEnabled]
  )

  if (filteredLinks.length < MIN_LINKS) {
    return null
  }

  return (
    <nav data-testid={testID} className={styles('base')}>
      {filteredLinks.map(({ key, label, to }) => (
        <NavLink key={key} to={to} className={styles('link')}>
          <Typography
            testID={`${testID}.label`}
            variant={'button'}
            text={label}
            color={'inherit'}
            weight={'bold'}
          />
        </NavLink>
      ))}
    </nav>
  )
}
