import { ProjectType, ResumeItemEducation } from '@project/interfaces'

export const mockEducationEmpty: ResumeItemEducation = {
  id: 0,
  title: '',
  type: ProjectType.Education,
  skills: []
}

export const mockEducation: ResumeItemEducation = {
  ...mockEducationEmpty,
  id: 1,
  title: 'Fundamentals of Neuroscience',
  subtitle: 'X-Series Short Course Program',
  description: [
    'The Electrical Properties of the Neuron',
    'Neurons and Networks',
    'The Brain'
  ],
  organization_id: 5,
  location_id: 0,
  dateStart: 1564995600000
}

export const mockEducations: ResumeItemEducation[] = [mockEducation]
