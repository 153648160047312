import { atom, useRecoilValue } from 'recoil'

import { DisplayMode } from '@project/interfaces'

import { LayoutState, LayoutStateMenuMode } from './layout.interface'

export const layoutState = atom<LayoutState>({
  key: 'layoutState',
  default: {
    menu: {
      showing: false,
      mode: LayoutStateMenuMode.Links
    },
    resume: {
      expanded: false
    },
    theme: {
      mode: 'light'
    },
    display: {
      mode: DisplayMode.Default
    }
  }
})

export const useLayout = () => {
  const layout = useRecoilValue(layoutState)

  return layout
}
