import { ResumeSkill } from '@project/interfaces'

export const mockSkillBase: ResumeSkill = {
  id: 0,
  label: null
}

/**
 * Simplest possible skill object. Extends
 * the base skill properties with the minimum
 * possible label string, which is an empty
 * string.
 */
export const mockSkillDefault: ResumeSkill = {
  ...mockSkillBase,
  label: ''
}

/**
 * Complete skill object. Extends the base
 * skill with properties with the example label,
 * `"JavaScript"`.
 */
export const mockSkill: ResumeSkill = {
  ...mockSkillBase,
  label: 'JavaScript'
}

/**
 * Generates a collection of skill objects by
 * iterating an array of skill `labels`. Each string
 * in `labels` become the `label` of the skill, and
 * the index becomes the skill `id` value.
 *
 * @param labels (optional) Collection of label strings
 */
export const generateMockSkillsWithLabels = (
  labels: ResumeSkill['label'][] = []
) =>
  labels.map((label, id) => ({
    id,
    label
  }))

/**
 * Implements the `generateMockSkillsWithLabels()`
 * function to generate a list of complete skill objects
 * with incremented ids and sample labels.
 */
export const mockSkills: ResumeSkill[] = generateMockSkillsWithLabels([
  'JavaScript',
  'TypeScript',
  'Node',
  'React',
  'WebPack',
  'Lerna',
  'Storybook'
])
