import { atom, atomFamily, selector } from 'recoil'

import { latestArticles } from '@project/business'
import { ArticleContent, ArticleData, ContentType } from '@project/interfaces'

export const articleIdentifiers = atom<ArticleData['id'][]>({
  key: 'articleIdentifiers',
  default: []
})

export const articleAtoms = atomFamily<ArticleData, ArticleData['id']>({
  key: 'articleAtoms',
  default: (id) => ({
    id,
    version: '1',
    author: {
      id: '',
      name: ''
    },
    checksum: '',
    language: 'en',
    views: 0,
    shares: 0,
    votes: 0,
    timeCreated: Date.now(),
    readDuration: null,
    active: false,
    flagged: false,
    title: null,
    subtitle: null,
    content: [],
    assets: [],
    highlights: []
  })
})

export const latestArticlesSelector = selector({
  key: 'latestArticlesSelector',
  get: async ({ get }) => await latestArticles()
})

export const contentState = atom<string[]>({
  key: 'contentState',
  default: []
})

export const contentFamily = atomFamily<ArticleContent, ArticleContent['key']>({
  key: 'contentFamily',
  default: {
    type: ContentType.Text
  }
})
