import { isSameYear } from 'date-fns'
import { atom, atomFamily, selectorFamily } from 'recoil'
import { v4 as generateId } from 'uuid'

import {
  Organization,
  Project,
  ProjectCategory,
  ProjectPeriod,
  ProjectType
} from '@project/interfaces'

import { GeoLocation } from '../../interfaces/resume/location.interface'
import { locationState } from './location-state'
import { orgState } from './org-state'
import { skillLabelSelector } from './skill-state'

const allProjectCategories = atom<ProjectCategory['key'][]>({
  key: 'allProjectCategories',
  default: [
    'experience',
    'education',
    'awards',
    'contributions',
    'publications',
    'projects'
  ]
})

const projectCategories = atomFamily({
  key: 'projectCategories',
  default: (key: ProjectCategory['key']): ProjectCategory => ({
    key,
    title: key,
    projects: []
  })
})

const projectState = atomFamily({
  key: 'projectState',
  default: (key: string): Project => ({
    id: key,
    type: ProjectType.Occupation,
    period: ProjectPeriod.Fulltime,
    title: 'title',
    description: [],
    organization: null,
    location: null,
    active: true,
    tags: [],
    dateStart: Date.now(),
    dateEnd: Date.now(),
    expanded: false
  })
})

/**
 * Select all projects matching the collection
 * of specified project keys.
 *
 * @param projectKeys Collection of unique project identifier keys
 */
const projectsWithKeys = selectorFamily({
  key: 'selectorFamily',
  get: (projectKeys: Project['id'][]) => ({ get }): Project[] =>
    projectKeys.reduce<Project[]>((projects, key) => {
      const project = get(projectState(key))

      if (project && project.active) {
        projects.push(project)
      }

      return projects
    }, [])
})

export type Collection = {
  id: string | undefined
  key: string
  title: string | null
  media: { thumbnail: string | undefined }
  startDate: number
  endDate: number
  items: [
    {
      expanded: boolean
      endDate: number | null | undefined
      title: string | null
      description: string
      startDate: number | null | undefined
      subtitle: string | null | undefined
      tags: string[]
      href: string | null | undefined
    }
  ]
}

const GROUP_TIME_TYPES: ProjectType[] = [ProjectType.Publication]

const shouldGroupOrganization = (
  last?: Collection,
  org?: Organization | null
): boolean => !!last && !!org && last.id === org.id

const shouldGroupYear = (type: ProjectType, last?: Collection): boolean =>
  !!last &&
  GROUP_TIME_TYPES.includes(type) &&
  isSameYear(last.startDate, last.endDate)

export const groupRoles = (
  memo: Collection[],
  project: Project,
  {
    tags,
    organization,
    location
  }: {
    tags: string[]
    location?: GeoLocation | null
    organization?: Organization | null
  }
): Collection[] => {
  const previous = memo[memo.length - 1]

  const subtitleText =
    (project.type === ProjectType.Occupation &&
      project.dateStart &&
      [[location?.city, location?.country].join(', ')].join(' - ')) ||
    project.subtitle

  const shouldAppendProject =
    shouldGroupOrganization(previous, organization) ??
    shouldGroupYear(project.type, previous)

  if (shouldAppendProject) {
    previous.items.push({
      ...project,
      description: project.description.join(''),
      subtitle: subtitleText,
      href: project.link,
      tags,
      startDate: project.dateStart ?? undefined,
      endDate: project.dateEnd ?? undefined
    })

    if (project.dateStart && previous.startDate > project.dateStart) {
      previous.startDate = project.dateStart || Date.now()
    }

    if (project.dateStart && previous.endDate < project.dateStart) {
      previous.endDate = project.dateStart || Date.now()
    }
  } else {
    memo.push({
      id: organization?.id,
      key: generateId(),
      title: organization?.nickname ?? organization?.name ?? project.title,
      media: { thumbnail: organization?.thumb },
      startDate: project.dateStart || 0,
      endDate: project.dateStart || Date.now(),
      items: [
        {
          ...project,
          endDate: project.dateEnd ?? undefined,
          description: project.description.join(''),
          startDate: project.dateStart ?? undefined,
          subtitle: subtitleText,
          tags,
          href: project.link
        }
      ]
    })
  }

  return memo
}

const selectProjectGroups = selectorFamily({
  key: 'selectProjectGroups',
  get: (projectKeys: Project['id'][]) => ({ get }): Collection[] => {
    const projects = get(projectsWithKeys(projectKeys))

    return projects.reduce<Collection[]>(
      (memo: Collection[], project: Project) => {
        const tags = get(skillLabelSelector(project.tags))
        const location = project.location
          ? get(locationState(project.location))
          : undefined
        const organization = project.organization
          ? get(orgState(project.organization))
          : null

        return groupRoles(memo, project, { tags, location, organization })
      },
      []
    )
  }
})

export {
  allProjectCategories,
  projectCategories,
  projectState,
  projectsWithKeys,
  selectProjectGroups
}
