/* eslint-disable max-len */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Link, Typography } from '@sigatoka/elements'
import style9 from 'style9'

import { Container, Layout } from '@project/components'

const styles = style9.create({
  base: {
    display: 'grid',
    gap: 'var(--size-500)'
  },
  body: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 'var(--size-400)',
    alignContent: 'center',
    padding: 'var(--size-600)',
    maxWidth: 800
  },
  location: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    gap: 'var(--size-100)'
  },
  links: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    gap: 'var(--size-100)'
  }
})

export const HomeScene = ({
  testID = 'home'
}: Partial<CommonProps>): React.ReactElement => {
  const { t } = useTranslation('home')

  return (
    <Layout testID={testID}>
      <Container testID={`${testID}.container`} className={styles('base')}>
        <div data-testid={`${testID}.location`} className={styles('location')}>
          <Icon testID={`${testID}.icon`} icon={'location'} />
          <Typography testID={`${testID}.label`} text={`Brisbane, Australia`} />
        </div>

        <Typography
          testID={`${testID}.title`}
          variant={'lead'}
          text={`Hello,`}
        />
        <Typography
          testID={`${testID}.description`}
          text={t('LandingDescription', {
            defaultValue: `Software development consultancy services, specializing in web, app, and microservice development`
          })}
        />
        <div data-testid={`${testID}.links`} className={styles('links')}>
          <Link testID={`${testID}.link`} href={`/contact`}>
            <Typography testID={`${testID}.text`} text={`Say Hello`} />
          </Link>
          <Link testID={`${testID}.link`} href={`https://mitch.pierias.com`}>
            <Typography testID={`${testID}.text`} text={`More Links`} />
          </Link>
        </div>
      </Container>
    </Layout>
  )
}
