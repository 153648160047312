import { ProjectType, ResumeItemPublication } from '@project/interfaces'

export const mockPublication: ResumeItemPublication = {
  id: 0,
  title: 'Advanced EOS Series',
  type: ProjectType.Publication,
  skills: []
}

export const mockPublicationNull: ResumeItemPublication = {
  ...mockPublication,
  dateStart: null,
  dateEnd: null
}

export const mockPublications: ResumeItemPublication[] = [
  {
    ...mockPublication,
    id: 0,
    organization_id: 3,
    title: 'Advanced EOS Series',
    subtitle: 'Part 9 - Payable Actions',
    dateStart: 1631273617000
  },
  {
    ...mockPublication,
    id: 1,
    organization_id: 4,
    dateStart: 1592732017000,
    title: 'The Complete Electron Pipeline',
    description: 'Development to Rollout'
  },
  {
    ...mockPublication,
    id: 2,
    organization_id: 3,
    title: 'Advanced EOS Series',
    dateStart: 1588379617000,
    subtitle: 'Part 6 - Contract-to-contract Communication'
  },
  {
    ...mockPublication,
    id: 3,
    organization_id: 3,
    dateStart: 1552980157000,
    title: 'Advanced EOS Series',
    subtitle: 'Part 5 - One-to-many Relationships'
  },
  {
    ...mockPublication,
    id: 4,
    organization_id: 3,
    title: 'Advanced EOS Series',
    dateStart: 1552378357000,
    subtitle: 'Part 4 - Table Uniqueness'
  },
  {
    ...mockPublication,
    id: 6,
    organization_id: 3,
    title: 'Advanced EOS Series',
    dateStart: 1551957157000,
    subtitle: 'Part 3 - Secondary Indexes'
  },
  {
    ...mockPublication,
    id: 7,
    organization_id: 4,
    dateStart: 1546423657000,
    title: 'The Dream Team - React with Electron'
  }
]
