import { format, isSameYear } from 'date-fns'

import { getRolePeriodText } from '@project/business'
import { Organization, ProjectGroup, ProjectType } from '@project/interfaces'
import { Collection } from '@project/scenes/resume-scene/resume-container.interface'

const GROUP_TIME_TYPES: ProjectType[] = [ProjectType.Publication]

const shouldGroupOrganization = (
  last?: Collection,
  org?: Organization | null
): boolean => !!last && !!org && last.key === org.id

const shouldGroupYear = (type: ProjectType, last?: Collection): boolean =>
  !!last &&
  GROUP_TIME_TYPES.includes(type) &&
  isSameYear(last.startDate, last.endDate)

export const groupRoles = (
  memo: Collection[],
  {
    organization,
    location,
    title,
    type,
    subtitle,
    description,
    dateStart,
    dateEnd,
    skills,
    link,
    expanded = false,
    period
  }: ProjectGroup
): Collection[] => {
  const last = memo[memo.length - 1]

  const subtitleText =
    (type === ProjectType.Occupation &&
      dateStart &&
      [
        getRolePeriodText(period),
        [location?.city, location?.country].join(', ')
      ].join(' - ')) ||
    subtitle

  const groupTitle =
    (type === ProjectType.Occupation &&
      (organization?.nickname || organization?.name)) ||
    (dateStart &&
      dateEnd &&
      `${organization?.nickname || organization?.name} between ${format(
        dateStart,
        'LLL do'
      )} and ${format(dateEnd, 'LLL do')}`)

  if (
    shouldGroupOrganization(last, organization) ||
    shouldGroupYear(type, last)
  ) {
    last.items.push({
      expanded,
      title,
      description,
      subtitle: subtitleText,
      tags: skills,
      startDate: dateStart ?? undefined,
      endDate: dateEnd ?? undefined
    })

    if (dateStart && last.startDate > dateStart) {
      last.startDate = dateStart || Date.now()
    }

    if (dateStart && last.endDate < dateStart) {
      last.endDate = dateStart || Date.now()
    }
  } else {
    memo.push({
      key: organization?.id,
      title: groupTitle || '',
      media: { thumbnail: organization?.thumb },
      startDate: dateStart || 0,
      endDate: dateStart || Date.now(),
      items: [
        {
          expanded,
          endDate: dateEnd ?? undefined,
          title,
          description,
          startDate: dateStart ?? undefined,
          subtitle: subtitleText,
          tags: skills,
          href: link
        }
      ]
    })
  }

  return memo
}
