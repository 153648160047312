import {
  differenceInCalendarMonths,
  format,
  isSameMonth,
  isSameYear
} from 'date-fns'

export const getTimestampText = (
  startTime?: number,
  endTime?: number | null,
  delimiter = ' - '
): string | void => {
  if (!startTime) return

  if (!endTime) {
    return format(startTime, 'LLL yyyy')
  }

  if (isSameMonth(startTime, endTime)) {
    return [format(startTime, 'dd'), format(endTime, 'dd LLL yyyy')].join(
      delimiter
    )
  }

  if (isSameYear(startTime, endTime)) {
    return [format(startTime, 'LLL'), format(endTime, 'LLL yyyy')].join(
      delimiter
    )
  }

  if (differenceInCalendarMonths(startTime, endTime) < 0) {
    return [format(startTime, 'LLL yyyy'), format(endTime, 'LLL yyyy')].join(
      delimiter
    )
  }

  if (isSameYear(startTime, Date.now())) {
    return format(startTime, 'dd LLL')
  }

  return [
    format(startTime, 'dd LLL yyyy'),
    format(endTime, 'dd LLL yyyy')
  ].join(delimiter)
}
