import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Switch, Typography } from '@sigatoka/elements'
import { useFlags } from 'launchdarkly-react-client-sdk'
import style9 from 'style9'

import { useTheme } from '@project/state'

import { CompanySlider } from '../company-slider'

interface FooterProps extends CommonProps {
  links?: any[]
}

const GROUPS = [
  [
    { label: 'Services', href: '/services' },
    { label: 'Projects', href: '/resume' },
    { label: 'Knowledge', href: '/blog' },
    { label: 'Tools', href: '/tools' }
  ],
  [
    { label: 'Settings', href: '/settings' },
    { label: 'Contact', href: '/contact' },
    { label: 'More', href: '/more' }
  ]
]

const SOCIALS = [
  {
    label: 'LinkedIn',
    icon: 'linkedin',
    href: 'https://www.linkedin.com/in/mitch-pierias'
  },
  { label: 'Medium', icon: 'medium', href: 'https://medium.com/@mitchpierias' },
  {
    label: 'Twitter',
    icon: 'twitter',
    href: 'https://twitter.com/MitchPierias'
  },
  {
    label: 'Instagram',
    icon: 'instagram',
    href: 'https://instagram.com/mitchpierias'
  },
  { label: 'TikTok', icon: 'tiktok', href: 'https://vm.tiktok.com/ZSenQB7Cr/' },
  {
    label: 'Facebook',
    icon: 'facebook',
    href: 'https://www.facebook.com/mitch.pierias.1'
  },
  { label: 'YouTube', icon: 'youtube', href: '' },
  { label: 'GitHub', icon: 'github', href: 'https://github.com/MitchPierias' }
]

const styles = style9.create({
  base: {
    width: '100vw',
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    gap: 'var(--size-100)',
    backgroundColor: 'var(--color-100)'
  },
  container: {
    display: 'grid',
    padding: 'var(--size-900)',
    gap: 'var(--size-900)'
  },
  group: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 'var(--size-200)',
    justifyContent: 'center'
  },
  navigation: {
    alignItems: 'center',
    gridAutoColumns: 'auto'
  },
  link: {
    color: 'var(--primary-500, #4D6BF6)',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
    textDecorationColor: 'transparent',
    textDecorationStyle: 'unset',
    textDecorationLine: 'none',
    flexWrap: 'nowrap',
    flexShrink: 0,
    cursor: 'pointer',
    ':hover': {
      color: 'var(--primary-400)'
    }
  },
  impressum: {
    maxWidth: 860,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gap: 'var(--size-100)'
  }
})

export const Footer = ({ testID }: FooterProps): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const flags = useFlags()
  const { theme, toggleTheme } = useTheme()

  const changeLanguage = useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLSelectElement>) => {
      if (currentTarget.value) {
        i18n.changeLanguage(currentTarget.value)
      }
    },
    [i18n]
  )

  return (
    <div data-testid={testID} className={styles('base')}>
      <div data-testid={`${testID}.container`} className={styles('container')}>
        <div
          data-testid={`${testID}.navigation`}
          className={styles('navigation')}
        >
          {GROUPS.map((groups, idx) => (
            <div key={`section-${idx}`} className={styles('group')}>
              {groups.map(({ label, href }, idx) => (
                <NavLink
                  data-testid={`${testID}.link`}
                  key={`item.${idx}`}
                  to={href}
                  className={styles('link')}
                >
                  <Typography
                    testID={`${testID}.label`}
                    className={styles('link')}
                    text={label}
                    variant={'link'}
                    component={'div'}
                  />
                </NavLink>
              ))}
            </div>
          ))}
        </div>

        {flags['socials'] && <div>Socials</div>}

        <div data-testid={`${testID}.controls`} className={styles('group')}>
          {flags['translation'] && (
            <select defaultValue={'en'} onBlur={changeLanguage}>
              <option value={'en'} defaultChecked>
                English
              </option>
              <option value={'jp'}>Japanese</option>
              <option value={'de'}>Danish</option>
              <option value={'nl'}>Dutch</option>
              <option value={'fr'}>French</option>
            </select>
          )}

          {flags['theme-toggling'] || true ? (
            <div data-testid={`${testID}.mode`}>
              <Switch
                testID={`${testID}.switch`}
                label={'Night mode'}
                onChange={toggleTheme}
                defaultChecked={theme === 'dark'}
              />
            </div>
          ) : null}
        </div>

        {flags['impressum'] && (
          <div
            data-testid={`${testID}.impressum`}
            className={styles('impressum')}
          >
            <Typography
              testID={`${testID}.copyright`}
              variant={'small'}
              color={'#fafafa'}
              text={t('FooterImpressumCopyright', {
                defaultValue: `Copyright 2022 - Pierias Pty Ltd`
              })}
            />
            <Typography
              testID={`${testID}.trademark`}
              variant={'small'}
              color={'#fafafa'}
              text={t('FooterImpressumTrademark', {
                defaultValue: `Pierias™ is a registered trademark of Pierias Pty Ltd.`
              })}
            />
          </div>
        )}
      </div>
    </div>
  )
}
