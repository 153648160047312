import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, Card, Overlay, Typography } from '@sigatoka/elements'
import { useRecoilValue } from 'recoil'
import style9 from 'style9'

import { Container, Layout } from '@project/components'
import { ProjectCategory } from '@project/containers/category'
import { useFeatures } from '@project/hooks'
import { DisplayMode } from '@project/interfaces'
import { allProjectCategories, layoutState } from '@project/state'

import { ResumeSceneProps } from './resume-scene.interface'

const styles = style9.create({
  header: {
    display: 'grid',
    gap: 'var(--size-100)',
    padding: 'var(--size-900)'
  }
})

export const ResumeScene = ({
  testID
}: ResumeSceneProps): React.ReactElement => {
  const { isEnabled } = useFeatures()
  const layout = useRecoilValue(layoutState)
  const categories = useRecoilValue(allProjectCategories)

  const [displayFilters, setDisplayFilters] = useState(false)

  const handleFilterClick = useCallback(
    () => setDisplayFilters((value) => !value),
    []
  )

  return (
    <Layout testID={testID}>
      <Container testID={`${testID}.container`}>
        <Helmet>
          <title>Resume</title>
          <meta name='description' content='Senior software engineer' />
          <link rel='canonical' href='/resume' />
        </Helmet>
        {layout.display.mode === DisplayMode.Print ? (
          <div className={styles('header')}>
            <Typography
              testID={`${testID}.header`}
              variant={'lead'}
              align={'center'}
              text={'Mitchell Pierias'}
            />
            <Typography
              testID={`${testID}.header`}
              variant={'title'}
              align={'center'}
              text={'Senior Frontend Developer'}
            />
            <Typography
              testID={`${testID}.header`}
              align={'center'}
              text={'Contracting - Brisbane, Australia (Remote)'}
            />
            <Typography
              testID={`${testID}.header`}
              align={'center'}
              text={'TypeScript · ReactJS · React Native · NodeJS · AWS'}
            />
            <Typography
              testID={`${testID}.header`}
              align={'center'}
              text={'mitch@pierias.com · 0431536911'}
            />
          </div>
        ) : null}

        {isEnabled('resume-filters') ? (
          <div data-testid={`${testID}.portal`}>
            <Overlay
              testID={`${testID}.overlay`}
              onClick={handleFilterClick}
              show={displayFilters}
            >
              <Card testID={`${testID}.card`} padded rounded>
                <div>
                  <Typography testID={`${testID}.title`} text={`Filters`} />
                </div>
              </Card>
            </Overlay>
            <Button
              testID={`${testID}.filters`}
              text={'Filters'}
              onClick={handleFilterClick}
            />
          </div>
        ) : null}

        {categories.map((category) => (
          <ProjectCategory
            key={`${testID}.${category}`}
            testID={`${testID}.category`}
            type={category}
          />
        ))}
      </Container>
    </Layout>
  )
}
