import React, { useEffect } from 'react'
import style9 from 'style9'

import { useTheme } from '@project/state'

const SCALE = 2
const INTERVAL = 4
const BASE = 1

const styles = style9.create({
  base: {
    boxSizing: 'border-box',
    height: '100%',
    fontFamily:
      'DIN 2014, Inter, Montserrat, Nunito, Noto Sans, Roboto, Open Sans',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    '--radius-100': `${BASE * Math.pow(SCALE, -7 / INTERVAL)}rem`,
    '--radius-200': `${BASE * Math.pow(SCALE, -6 / INTERVAL)}rem`,
    '--radius-300': `${BASE * Math.pow(SCALE, -5 / INTERVAL)}rem`,
    '--radius-400': `${BASE * Math.pow(SCALE, -4 / INTERVAL)}rem`,
    '--radius-500': `${BASE * Math.pow(SCALE, 1 / INTERVAL)}rem`,
    '--radius-600': `${BASE * Math.pow(SCALE, -1 / INTERVAL)}rem`,
    '--radius-700': `${BASE * Math.pow(SCALE, -2 / INTERVAL)}rem`,
    '--radius-800': `${BASE * Math.pow(SCALE, -3 / INTERVAL)}rem`,
    '--radius-900': `${BASE * Math.pow(SCALE, -4 / INTERVAL)}rem`,
    '--size-100': `${BASE * Math.pow(SCALE, -3 / INTERVAL)}rem`,
    '--size-200': `${BASE * Math.pow(SCALE, -2 / INTERVAL)}rem`,
    '--size-300': `${BASE * Math.pow(SCALE, -1 / INTERVAL)}rem`,
    '--size-400': `${BASE * Math.pow(SCALE, 0 / INTERVAL)}rem`,
    '--size-500': `${BASE * Math.pow(SCALE, 1 / INTERVAL)}rem`,
    '--size-600': `${BASE * Math.pow(SCALE, 2 / INTERVAL)}rem`,
    '--size-700': `${BASE * Math.pow(SCALE, 3 / INTERVAL)}rem`,
    '--size-800': `${BASE * Math.pow(SCALE, 4 / INTERVAL)}rem`,
    '--size-900': `${BASE * Math.pow(SCALE, 5 / INTERVAL)}rem`,
    '--padding-100': 'calc(var(--size-100)/4)',
    '--padding-200': 'calc(var(--size-100)/2)',
    '--padding-400': 'var(--size-100)',
    '--black': '#222222',
    '--white': '#fafafa',
    '--color-050': '#eeeeee',
    '--color-100': '#d6d6d6',
    '--color-200': '#bebebe',
    '--color-300': '#a7a7a7',
    '--color-400': '#8f8f8f',
    '--color-500': '#777777',
    '--color-600': '#5f5f5f',
    '--color-700': '#474747',
    '--color-800': '#303030',
    '--color-900': '#181818',
    '--primary-100': '#212f2a',
    '--primary-200': '#32473f',
    '--primary-300': '#435f54',
    '--primary-400': '#517265',
    '--primary-500': '#729a8b',
    '--primary-600': '#85a89b',
    '--primary-700': '#a6c0b5',
    '--primary-800': '#c8d8d1',
    '--primary-900': '#d1ded9',
    '--secondary-100': '#307a16',
    '--secondary-200': '#399019',
    '--secondary-300': '#41a61d',
    '--secondary-400': '#4abb21',
    '--secondary-500': '#52d125',
    '--secondary-600': '#71df4a',
    '--secondary-700': '#82e360',
    '--secondary-800': '#93e775',
    '--secondary-900': '#a4ea8b',
    '--tertiary-100': '#281e1e',
    '--tertiary-200': '#3d2d2e',
    '--tertiary-300': '#513c3d',
    '--tertiary-400': '#664c4d',
    '--tertiary-500': '#7a5b5c',
    '--tertiary-600': '#8f6a6b',
    '--tertiary-700': '#ae9293',
    '--tertiary-800': '#bda7a8',
    '--tertiary-900': '#ccbbbc',
    '--loading':
      'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
    transitionProperty: 'backgroundColor, color',
    transitionDuration: '600ms',
    transitionDelay: '0',
    transitionTimingFunction: 'ease-in-out'
  },
  light: {
    '--black': '#21242d',
    '--white': '#fafafa',
    '--color-050': '#2b2f3a',
    '--color-100': '#363c4a',
    '--color-200': '#404654',
    '--color-300': '#58627d',
    '--color-400': '#6c7793',
    '--color-500': '#7b859c',
    '--color-600': '#98a0b3',
    '--color-700': '#c5c9d4',
    '--color-800': '#e2e4e9',
    '--color-900': '#f1f2f4',
    '--secondary-100': '#341512',
    '--secondary-200': '#4e1f1c',
    '--secondary-300': '#692a25',
    '--secondary-400': '#9d3f38',
    '--secondary-500': '#b74941',
    '--secondary-600': '#c36059',
    '--secondary-700': '#cd7a74',
    '--secondary-800': '#d6938e',
    '--secondary-900': '#e0aca8',
    '--tertiary-100': '#281e1e',
    '--tertiary-200': '#3d2d2e',
    '--tertiary-300': '#513c3d',
    '--tertiary-400': '#664c4d',
    '--tertiary-500': '#7a5b5c',
    '--tertiary-600': '#8f6a6b',
    '--tertiary-700': '#ae9293',
    '--tertiary-800': '#bda7a8',
    '--tertiary-900': '#ccbbbc',
    '--accent-100': '#34093e',
    '--accent-200': '#4e0e5d',
    '--accent-300': '#82179b',
    '--accent-400': '#9c1cba',
    '--accent-500': '#c23be1',
    '--accent-600': '#cc5ae6',
    '--accent-700': '#d57aea',
    '--accent-800': '#dc8eee',
    '--accent-900': '#df99ef',
    '--gradientColorZero': '#a960ee',
    '--gradientColorOne': '#ff333d',
    '--gradientColorTwo': '#90e0ff',
    '--gradientColorThree': '#ffcb57',
    '--gradientColorZeroTransparent': 'rgba(169,96,238,0)',
    '--gradientColorOneTransparent': 'rgba(255,51,61,0)',
    '--gradientColorTwoTransparent': 'rgba(144,224,255,0)',
    '--gradientColorThreeTransparent': 'rgba(255,203,87,0)',
    '--gradient-angle': 'var(--angleStrong)',
    '--gradient-height':
      // eslint-disable-next-line max-len
      'calc(100% + var(--sectionPaddingTop) + var(--transformOriginX)*var(--sectionAngleSin))',
    backgroundColor: 'var(--white)'
  },
  dark: {
    '--accent-500': '#ED1A5C',
    '--black': '#21242d',
    '--white': '#fafafa',
    '--color-050': '#f1f2f4',
    '--color-100': '#e2e4e9',
    '--color-200': '#c5c9d4',
    '--color-300': '#98a0b3',
    '--color-400': '#7b859c',
    '--color-500': '#6c7793',
    '--color-600': '#58627d',
    '--color-700': '#404654',
    '--color-800': '#363c4a',
    '--color-900': '#2b2f3a',
    '--primary-100': '#d1ded9',
    '--primary-200': '#c8d8d1',
    '--primary-300': '#a6c0b5',
    '--primary-400': '#85a89b',
    '--primary-500': '#729a8b',
    '--primary-600': '#517265',
    '--primary-700': '#435f54',
    '--primary-800': '#32473f',
    '--primary-900': '#212f2a',
    backgroundColor: 'var(--black)'
  },
  ':hover': {
    textDecorationColor: 'transparent',
    textDecorationLine: 'none'
  },
  ':visited': {
    textDecorationColor: 'transparent',
    textDecorationLine: 'none'
  },
  ':link': {
    textDecorationColor: 'transparent',
    textDecorationLine: 'none'
  },
  ':active': {
    textDecorationColor: 'transparent',
    textDecorationLine: 'none'
  }
})

export const Themed = ({
  children
}: {
  children: React.ReactElement
}): React.ReactElement => {
  const { theme } = useTheme()

  useEffect(() => {
    const portalRoot = document.getElementsByTagName('body')[0]

    portalRoot?.setAttribute(
      'class',
      styles('base', {
        light: theme === 'light',
        dark: theme === 'dark'
      })
    )
  }, [theme])

  return children
}
