/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react'
import { Button, Card, Tooltip, Typography } from '@sigatoka/elements'
import { addYears } from 'date-fns'
import { useRecoilState, useRecoilValue } from 'recoil'
import style9 from 'style9'
import { v4 } from 'uuid'

import {
  holdingStateFamily,
  holdingStatementSelector
} from '@project/state/financial/expense.state'

import { useRegionalRules } from '../hooks/use-regional-rules'
import { ExpenseRow, getPriceText } from './expense-row'

const styles = style9.create({
  base: {},
  horizontal: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 'var(--size-100)'
  }
})

export const AssetSection = ({
  testID,
  id
}: CommonProps & { id: string }): React.ReactElement => {
  const [holding, setHolding] = useRecoilState(holdingStateFamily(id))
  const report = useRecoilValue(holdingStatementSelector(id))
  const { getTaxRules } = useRegionalRules('AU')

  // const taxRate = useMemo(
  //   () => getTaxRules(holding.taxCategory).findBracket(profit),
  //   [holding.taxCategory, profit]
  // )

  const changeYearRange = useCallback(
    ({ currentTarget: { value } }: React.FocusEvent<HTMLSelectElement>) => {
      const dateStart = holding.dateStart ?? Date.now()
      const dateEnd = addYears(dateStart, Number(value) + 1).getTime()

      setHolding((state) => ({
        ...state,
        dateStart,
        dateEnd
      }))
    },
    [id]
  )

  const changeCategory = useCallback(
    ({ currentTarget: { value } }: React.FocusEvent<HTMLSelectElement>) =>
      setHolding((state) => ({
        ...state,
        taxCategory: value as any
      })),
    [id]
  )

  const addExpense = useCallback(
    (type: 'incoming' | 'outgoing') => (): void =>
      setHolding((state) => ({
        ...state,
        [type]: [...state[type], v4()]
      })),
    [id]
  )

  return (
    <Card testID={testID} padded rounded>
      <div className={styles('horizontal')}>
        <Typography
          testID={`${testID}.title`}
          text={`${holding.label ?? 'Holding'}`}
        />
        <select defaultValue={holding.taxCategory} onBlur={changeCategory}>
          <option value={'organization'}>Organization</option>
          <option value={'individual'}>Individual</option>
        </select>
        <select defaultValue={0} onBlur={changeYearRange}>
          <option value={0}>None</option>
          <option value={1}>Next year</option>
          <option value={2}>Two years</option>
          <option value={3}>Three years</option>
        </select>
      </div>
      <div>
        <div className={styles('horizontal')}>
          <div>
            <div className={styles('horizontal')}>
              <Typography
                testID={`${testID}.title`}
                text={`Incoming`}
                variant={'subheading'}
              />
              <Button
                testID={`${testID}.add`}
                icon={'plus'}
                text={'add'}
                variant={'link'}
                onClick={addExpense('incoming')}
              />
            </div>
            <div style={{ display: 'grid', gap: 'var(--size-100)' }}>
              {holding.incoming.map((id) => (
                <ExpenseRow testID={`${testID}.incoming`} key={id} id={id} />
              ))}
            </div>
            <Typography
              testID={`${testID}.total`}
              text={`Total ${getPriceText(report.incoming)}`}
            />
          </div>
          <div>
            <div className={styles('horizontal')}>
              <Typography
                testID={`${testID}.title`}
                text={`Outgoing`}
                variant={'subheading'}
              />
              <Button
                testID={`${testID}.add`}
                icon={'plus'}
                text={'add'}
                variant={'link'}
                onClick={addExpense('outgoing')}
              />
            </div>
            <div style={{ display: 'grid', gap: 'var(--size-100)' }}>
              {holding.outgoing.map((id) => (
                <ExpenseRow testID={`${testID}.outgoing`} key={id} id={id} />
              ))}
            </div>
            <Typography
              testID={`${testID}.total`}
              text={`Total ${getPriceText(report.outgoing)}`}
            />
          </div>
        </div>
        <Card testID={`${testID}.report`} padded>
          <div style={{ display: 'grid', gap: 'var(--size-100)' }}>
            <div className={styles('horizontal')}>
              <Typography testID={`${testID}.total`} text={`Profit`} />
              <Typography
                testID={`${testID}.total`}
                text={getPriceText(report.profit)}
              />
            </div>
            {report.fees.map((fee) => (
              <div
                key={`${fee.label}.${fee.rate}`}
                className={styles('horizontal')}
              >
                <Typography
                  testID={`${testID}.tax`}
                  text={`${fee.label} (${Math.floor(fee.rate * 100)}%)`}
                />
                <Typography
                  testID={`${testID}.tax`}
                  text={getPriceText(fee.value)}
                />
              </div>
            ))}
            <div className={styles('horizontal')}>
              <Tooltip
                testID={`${testID}.tip`}
                label={`Profit after fees`}
                position={'bottom'}
              >
                <Typography
                  testID={`${testID}.realized`}
                  variant={'subtitle'}
                  text={`Realized`}
                />
              </Tooltip>
              <Typography
                testID={`${testID}.realized`}
                variant={'subtitle'}
                text={getPriceText(report.realized)}
              />
            </div>

            <div className={styles('horizontal')}>
              <Tooltip
                testID={`${testID}.tip`}
                label={`Starting value`}
                position={'bottom'}
              >
                <Typography testID={`${testID}.initial`} text={`Initial`} />
              </Tooltip>
              <Typography
                testID={`${testID}.initial`}
                text={getPriceText(report.value)}
              />
            </div>

            <div className={styles('horizontal')}>
              <Tooltip
                testID={`${testID}.tip`}
                label={`Gross value including realized profits`}
                position={'bottom'}
              >
                <Typography
                  testID={`${testID}.gross`}
                  variant={'subtitle'}
                  text={`Total`}
                />
              </Tooltip>
              <Typography
                testID={`${testID}.gross`}
                variant={'subtitle'}
                text={getPriceText(report.gross)}
              />
            </div>
          </div>
        </Card>
      </div>
    </Card>
  )
}
