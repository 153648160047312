import { DisplayMode } from '@project/interfaces'

export type LayoutStateMenu = {
  showing: boolean
  mode: LayoutStateMenuMode
}

export type LayoutStateResume = {
  expanded: boolean
}

export type ThemeMode = 'light' | 'dark'

export type LayoutThemeState = {
  mode: ThemeMode
}

export type LayoutDisplayState = {
  mode: DisplayMode
}

export type LayoutState = {
  menu: LayoutStateMenu
  resume: LayoutStateResume
  theme: LayoutThemeState
  display: LayoutDisplayState
}

export enum LayoutStateMenuMode {
  Links = 'links'
}
