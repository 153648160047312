import { ProjectType, ResumeItemAward } from '@project/interfaces'

export const mockAwardEmpty: ResumeItemAward = {
  id: 0,
  title: 'Award',
  type: ProjectType.Award,
  skills: []
}

export const mockAward: ResumeItemAward = {
  ...mockAwardEmpty,
  id: 1,
  title: 'Startup Catalyst Alumni',
  description:
    'Inaugural Member of the Startup Catalyst Silicon Valley Youth Mission',
  organization_id: 6,
  location_id: 0,
  dateStart: 1564995600000
}

export const mockAwardSkills: ResumeItemAward = {
  ...mockAward,
  id: 2,
  skills: [0]
}

export const mockAwards: ResumeItemAward[] = [mockAward]
