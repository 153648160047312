import React, { useCallback, useMemo, useState } from 'react'
import { Card, Input, Typography } from '@sigatoka/elements'
import { useRecoilState } from 'recoil'

import { profileState } from '@project/state'

type Position = {
  open: number
  stop: number
  spread: number
}

type Result = {
  units: number
  value: number
  loss: number
}

export const PositionSize = ({ testID }: CommonProps): React.ReactElement => {
  const [position, setPosition] = useState({
    open: 20000,
    stop: 18000,
    spread: 0.3
  })
  const [profile, setProfile] = useRecoilState(profileState)

  const result = useMemo<Result>(() => {
    const lotSize = 10
    const lossTotal = (position.open - position.stop) * lotSize
    const lossPercentage = lossTotal / position.open
    const allowedLoss = profile.balance * profile.riskRatio
    const units = allowedLoss / lossTotal
    const loss = units * position.open

    // Determine a position risk score based on the accepted loss against the
    // average frequency of wins and their average percentage

    return {
      units,
      value: loss,
      loss: loss * lossPercentage
    }
  }, [position, profile])

  const updatePosition = useCallback(
    (attr: keyof Position) => ({
      currentTarget
    }: React.ChangeEvent<HTMLInputElement>) => {
      setPosition((existing) => ({
        ...existing,
        [attr]: parseFloat(currentTarget.value)
      }))
    },
    [setPosition]
  )

  const updateRisk = useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      setProfile((existing) => ({
        ...existing,
        risk: parseFloat(currentTarget.value)
      }))
    },
    [setProfile]
  )

  return (
    <div
      data-testid={testID}
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 'var(--size-100)',
        padding: 'var(--size-100)'
      }}
    >
      <div>
        <Input
          testID={`${testID}.price`}
          label={'Open price'}
          defaultValue={position.open}
          onChange={updatePosition('open')}
        />
        <Input
          testID={`${testID}.stop`}
          label={'Stop loss price'}
          defaultValue={position.stop}
          onChange={updatePosition('stop')}
        />
        <Input
          testID={`${testID}.risk`}
          label={'Risk percentage'}
          defaultValue={profile.riskRatio}
          onChange={updateRisk}
        />
      </div>

      <Card testID={`${testID}.output`} padded rounded>
        <Typography
          testID={`${testID}.units`}
          variant={'title'}
          text={`${result.units} units`}
        />
        <Typography
          testID={`${testID}.value`}
          text={`$${result.value.toFixed(2)} value`}
        />
        <Typography
          testID={`${testID}.loss`}
          variant={'small'}
          color={'red'}
          text={`$${result.loss.toFixed(2)} risk value`}
        />
      </Card>
    </div>
  )
}
