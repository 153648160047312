import React, { useCallback, useState } from 'react'
import { Button, Input, Overlay } from '@sigatoka/elements'
import style9 from 'style9'

import { ContactFormDetails, EntityApiService } from '@project/business'
import { Container } from '@project/components'

const service = new EntityApiService()

const styles = style9.create({
  base: {
    padding: 'var(--size-400)'
  },
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 'var(--size-100)'
  }
})

export const ContactScene = ({ testID = 'contact' }): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [details, setDetails] = useState<Partial<ContactFormDetails>>({})

  const updateDetails = useCallback(
    (key: keyof ContactFormDetails) => (
      event: React.ChangeEvent<HTMLInputElement>
    ): void =>
      setDetails((prevDetails) => ({
        ...prevDetails,
        [key]: event.target.value
      })),
    []
  )

  const clearErrors = useCallback(() => setError(null), [])

  const handleSuccess = useCallback(() => {
    setDetails({})
  }, [details])

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      setLoading(true)

      service
        .saveDetails(details)
        .catch(setError)
        .then((success) => success && handleSuccess)
        .finally(() => setLoading(false))
    },
    [details]
  )

  return (
    <Overlay testID={`${testID}.overlay`}>
      <Container testID={testID}>
        <form onSubmit={submitForm} className={styles('form')}>
          <Input
            testID={`${testID}.name`}
            name={'name'}
            label={`Name`}
            placeholder={'Your name'}
            onChange={updateDetails('name')}
            required
          />
          <Input
            testID={`${testID}.email`}
            name={'email'}
            label={`Email`}
            placeholder={'example@email.com'}
            onChange={updateDetails('email')}
          />
          <Input
            testID={`${testID}.phone`}
            name={'phone'}
            label={`Phone`}
            placeholder={'+614 12 345 678'}
            onChange={updateDetails('mobile')}
          />
          <Button
            testID={`${testID}.submit`}
            type={'submit'}
            text={'Submit'}
            loading={loading}
            disabled={!!error}
          />
        </form>
      </Container>
    </Overlay>
  )
}
