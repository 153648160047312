import { atom, atomFamily, selector, selectorFamily } from 'recoil'

import { ProjectSkill } from '@project/interfaces'

import { filterState } from '../filters'

const skillIndexes = atom<ProjectSkill['key'][]>({
  key: 'skillIndexes',
  default: []
})

const skillFilterState = selector<number[]>({
  key: 'skillFilterState',
  get: ({ get }) => {
    const filters = get(filterState)

    return filters.skills
  }
})

const skillState = atomFamily({
  key: 'skillState',
  default: (key: ProjectSkill['key']): ProjectSkill => ({
    key,
    label: null
  })
})

const skillLabelSelector = selectorFamily({
  key: 'skillLabelSelector',
  get: (keys: number[]) => ({ get }): string[] =>
    keys.reduce<string[]>((skills, key) => {
      const skill = get(skillState(key))

      if (skill && skill.label) {
        skills.push(skill.label)
      }

      return skills
    }, [])
})

const skillFilterTextState = selector<ProjectSkill[]>({
  key: 'skillFilterTextState',
  get: ({ get }) => {
    const filters = get(skillFilterState)
    const skills = get(skillIndexes)

    if (!skills) return []

    return skills.reduce<ProjectSkill[]>((collection, index) => {
      if (filters.includes(index)) {
        return collection
      }

      const skill = get(skillState(index))

      if (skill && skill.label) {
        collection.push(skill)
      }

      return collection
    }, [])
  }
})

export { skillFilterTextState, skillIndexes, skillLabelSelector, skillState }
