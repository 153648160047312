// import { randomUUID } from 'crypto'

enum ProjectChecksumVersionAlgorithm {
  '1.0' = 'sha256'
}

type Asset = {
  type: string
}

type ImageAsset = Asset & {
  src: string
  type: 'image'
  format: string
  size: string
}

type ProjectClient = {
  id: string
  image: ImageAsset
  name: string
  displayName: string
}

export class Project {
  public static version = '1.0'
  public id: string
  public client: ProjectClient | null = null
  public timeCreated: Date
  public timeUpdated: Date | null = null
  public active = false
  public flagged = false
  public title: string | null = null
  public subtitle: string | null = null
  public description: string[] = []

  constructor() {
    this.id = 'random' //randomUUID()
    this.timeCreated = new Date()
  }

  public static fromRecord = (data: Record<string, unknown>): Project =>
    new Project()

  public static fromDynamoRecord = (record: DynamoProject): Project => {
    const article = new Project()

    return article
  }

  public toDynamoRecord = (): DynamoProject => ({
    Version: Project.version,
    ProjectId: this.id,
    DateUpdated: this.timeUpdated?.toUTCString() || null,
    DateCreated: this.timeCreated.toUTCString(),
    Title: this.title || null,
    Subtitle: this.subtitle || null,
    Active: this.active,
    Flagged: this.flagged
  })

  public get data(): ProjectData {
    return {
      version: Project.version,
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      timeCreated: this.timeCreated.getTime(),
      timeUpdated: this.timeUpdated?.getTime(),
      active: this.active,
      flagged: this.flagged
    }
  }
}
