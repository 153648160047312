/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { ProjectType, ResumeItemOccupation } from '@project/interfaces'

export const mockResumeRole: ResumeItemOccupation = {
  id: 1,
  title: 'Software Engineer',
  organization: 'Lamington',
  skills: ['TypeScript', 'Mocha', 'Docker'],
  type: 'experience'
}

export const mockResumeRoleLarge: ResumeItemOccupation = {
  id: 0,
  title: 'Senior Full Stack Developer',
  organization: {
    name: 'Credit Union Australia',
    thumb:
      'https://media.glassdoor.com/sqll/624191/credit-union-australia-squarelogo-1547422687793.png',
    nickname: 'CUA'
  },
  description: [
    'Implemented the Personal Loan Application UI redesign',
    'Consolidate and exposed multiple SOAP services through a single Lambda GraphQL server',
    'Lead the creation of an internal ReactJS component library',
    'Implemented multiple monorepo project structures with style and usability guidelines'
  ],
  skills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  type: ProjectType.Occupation,
  location: {
    city: 'Gold Coast',
    country: 'Australia'
  },
  dateStart: 1556528400000,
  dateEnd: 1564417800000
}

export const mockResumeRoles: ResumeItemOccupation[] = [
  mockResumeRole,
  {
    id: 1,
    title: 'Software Engineer',
    organization: 'Lamington',
    description:
      'Co-developed a CLI tool for building, testing and deploying EOSIO smart contracts within localized docker containers. Lamington additionally generates TypeScript type definitions from contract ABI’s to simplify integration with contracts.',
    skills: [0, 5, 4],
    link: 'https://github.com/CoinageCrypto/lamington',
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1552899600000,
    dateEnd: 1560848400000
  },
  {
    id: 1,
    title: 'Full Stack Developer',
    organization: 'Harvey',
    link: 'https://github.com/MitchPierias/Harvey',
    description: [
      'Automated aggregation and filtering of RSS news feeds',
      'Triggered Lambda microservices for background sentiment analysis',
      'Stored articles alongside associated topics and subjects in a graph database',
      'Exposed an Apollo GraphQL microservice layer for retrieval of results',
      'Implemented a user interface in React and migrated to Angular 7'
    ],
    skills: [5, 8, 1, 6, 4],
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1556528400000,
    dateEnd: null
  },
  {
    id: 1,
    title: 'Software Engineer',
    organization: 'Reactron',
    link: 'https://npmjs.com/package/create-reactron-app',
    description:
      'A simple NodeJS command line tool for easily generating Electron with React project boilerplate.',
    skills: ['TypeScript'],
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1516379400000,
    dateEnd: 1519835400000
  },
  {
    id: 1,
    title: ['Full Stack Developer', 'Blockchain Developer'],
    organization: 'Syndicate',
    link: 'https://www.syndicate.games',
    description: [
      'Developed ReactJS user interface.',
      'Designed the EOSIO (C++) smart contracts and autonomous organization.',
      'Deployed to an IPFS node on AWS EC2.'
    ],
    skills: [
      'ReactJS',
      'EOSIO',
      'C++',
      'IPFS',
      'TypeScript',
      'AWS (S3/EC2/CloudFront)',
      'Nginx',
      'Webpack',
      'Nginx'
    ],
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1518971400000,
    dateEnd: 1550507400000
  },
  {
    id: 1,
    title: 'Software Developer',
    organization: 'Agora',
    link: 'https://agora.pierias.com',
    description: [
      'Integrated a ReactJS user interface within an Electron application',
      'Served through Gastby page hosted on AWS CloudFront and S3'
    ],
    skills: [
      'ReactJS',
      'Electron',
      'Electron Builder',
      'WebPack',
      'GatsbyJS',
      'AWS (S3/CloudFront)',
      'Mocha'
    ],
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1534609800000,
    dateEnd: null
  },
  {
    id: 1,
    title: ['Founder', 'iOS Developer', 'Backend Developer'],
    organization: 'Sprout',
    description: [
      'Participant in the Bond University Business Accelerator',
      'Implemented a Mesh Network for offline peer-to-peer communication'
    ],
    skills: [
      'Objective-C',
      'NodeJS',
      'Neo4j',
      'Redis',
      'Nginx',
      'AWS (EC2/ElastiCache)'
    ],
    type: ProjectType.Occupation,
    location: {
      city: 'Gold Coast',
      country: 'Australia'
    },
    dateStart: 1366302600000,
    dateEnd: 1492533000000
  }
]
