import { selector } from 'recoil'

import { layoutState } from './layout.state'

export const menuShowingState = selector({
  key: 'menuState',
  get: ({ get }) => {
    const layout = get(layoutState)

    return layout.menu.showing
  }
})
