import { addYears, differenceInYears, isAfter, isBefore } from 'date-fns'
import { atom, atomFamily, selector, selectorFamily } from 'recoil'

import { taxRegions } from '@project/scenes/tool-scene/constants/tax-rates'
import { Rules } from '@project/scenes/tool-scene/functions/rule-tools'
import {
  ExpenseFrequency,
  ExpenseType
} from '@project/scenes/tool-scene/tool-scene.interface'
import {
  convertFrequency,
  normalizeValue
} from '@project/scenes/tool-scene/tool-utils'

type TaxCategory = 'organization' | 'individual'

type BalanceCategories = 'lifestyle' | 'food' | 'entertainment'

export type CashFlow = {
  label: string | null
  value: number
  type: ExpenseType
  frequency: ExpenseFrequency
  category?: BalanceCategories | string | null
  dateStart?: number
  dateEnd?: number | null
}

type Asset = {
  label: string | null
  value: number
  dateStart?: number
  dateEnd?: number | null
  /**
   * Yield is a return measure for an investment
   * over a set period of time, expressed as a percentage.
   *
   * Yield includes the interest earned or
   * dividends received from holding a particular
   * security.
   */
  yield?: number
  /**
   * Percentage as a multiple that
   * this asset increases by per-annum
   */
  appreciation?: number
  /**
   * Percentage as a multiple of the
   * asset value that is paid out of
   * this asset to its owner/s.
   */
  dividends?: number
  fees?: number
}

type Balance = {
  label: string | null
  region: 'AU'
  value: number
  taxCategory: TaxCategory
  dateStart?: number
  dateEnd?: number | null
  incoming: string[]
  outgoing: string[]
  holdings: string[]
  /** Should goals be generated based on future dated expenses and assets? */
  goals?: string[]
}

type Fee = { label: string; rate: number; value: number }

type Financial = {
  /**
   * The initial or starting value of
   * the asset.
   */
  par: number
  value: number
  profit: number
  gross: number
  realized: number
  fees: Fee[]
  incoming: number
  outgoing: number
  /**
   * Internal Rate of Return based on a
   * series of projected cash flows.
   */
  irr?: number
  /**
   * Annual rate of return.
   * Next twelve months revenue.
   */
  arr?: number
  /**
   * Simplified Internal Rate of Return.
   * Assumes all cash in-flows and
   * out-flows distributions happen at the
   * exact same time once per year.
   */
  xirr?: number
  /**
   * The Dept Service Coverage Ratio (DSCR) is
   * often used by lenders to calculate the
   * maximum loan size.
   *
   * Back solves the total loan amount, given
   * a maximum loan payment, amortization period,
   * and an interest rate.
   */
  dscr?: number
}

export const holdingsState = atom<string[]>({
  key: 'holdingsState',
  default: []
})

export const holdingStateFamily = atomFamily<Balance, string>({
  key: 'holdingStateFamily',
  default: {
    label: null,
    region: 'AU',
    value: 0,
    incoming: [],
    outgoing: [],
    holdings: [],
    taxCategory: 'individual'
  }
})

const getFees = (input: number, rules: Rules[]): Fee[] =>
  rules.reduce<Fee[]>((output, rule) => {
    const rate = rule.findBracket(input)

    output.push({
      label: rule.label,
      rate,
      value: input * rate
    })

    return output
  }, [])

const applyFees = (input: number, fees: Fee[]): number =>
  fees.reduce((output, { value }) => output - value, input)

export const holdingStatementSelector = selectorFamily<Financial, string>({
  key: 'holdingStatementSelector',
  get: (key) => ({ get }): Financial => {
    const holding = get(holdingStateFamily(key))
    const incoming = get(holdingValueSelector(holding.incoming))
    const outgoing = get(holdingValueSelector(holding.outgoing))
    const category = taxRegions[holding.region]
    const rules = category[holding.taxCategory]
    const taxRule = new Rules(rules.tax)
    const profit = incoming - outgoing
    const fees = getFees(profit, [taxRule])
    const realized = applyFees(profit, fees)
    const timeSpan = differenceInYears(
      holding.dateEnd ?? addYears(Date.now(), 1),
      holding.dateStart ?? Date.now()
    )
    const value = realized * (timeSpan - 1)
    const gross = holding.value + value

    return {
      par: 0,
      value,
      profit,
      gross,
      realized,
      fees,
      incoming,
      outgoing
    }
  }
})

export const expenseState = atom<string[]>({
  key: 'expenseState',
  default: []
})

export const holdingValueSelector = selectorFamily<number, string[]>({
  key: 'holdingValueSelector',
  get: (expenses = [], years = 0) => ({ get }): number =>
    expenses.reduce((total, id) => {
      const beginDate = Date.now()
      const cutOffDate = addYears(beginDate, years + 1)
      const { value = 0, frequency, dateStart, dateEnd } = get(
        expenseStateFamily(id)
      )

      if (dateStart && isBefore(dateStart, beginDate)) {
        if (!dateEnd || isAfter(dateEnd, cutOffDate)) {
          const adjustedTotal = normalizeValue(value, frequency)

          return (
            total +
            Number(convertFrequency(adjustedTotal, ExpenseFrequency.Yearly))
          )
        }

        return total
      }

      return total
    }, 0)
})

export const wealthSelector = selectorFamily<number, number>({
  key: 'wealthSelector',
  get: (year = 0) => ({ get }): number => {
    const holdings = get(holdingsState)

    return holdings.reduce((total, id) => {
      const statement = get(holdingStatementSelector(id))

      total += statement.value

      return total
    }, 0)
  }
})

export const expenseStateFamily = atomFamily<CashFlow, string>({
  key: 'expenseStateFamily',
  default: {
    label: null,
    value: 0,
    type: ExpenseType.Unknown,
    frequency: ExpenseFrequency.Yearly,
    dateStart: Date.now(),
    dateEnd: null
  }
})

export const selectedElementId = atom<string | null>({
  key: 'selectedElementId',
  default: null
})

export const selectedElementSelector = selector<CashFlow | null>({
  key: 'selectedElementSelector',
  get: ({ get }) => {
    const selectedId = get(selectedElementId)

    if (selectedId) {
      return get(expenseStateFamily(selectedId))
    }

    return null
  },
  set: ({ get, set }, element) => {
    const selectedId = get(selectedElementId)

    if (selectedId && element) {
      set(expenseStateFamily(selectedId), element)
    }
  }
})
