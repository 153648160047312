import { captureException } from '@sentry/react'
import axios from 'axios'

import { Article } from '@project/business'

export const latestArticles = (): Promise<Article[]> =>
  axios
    .get('https://api.dev.pierias.com/articles/latest')
    .then((response) => response.data.data.articles)
    .catch((error) => {
      captureException(error, { tags: { section: 'article' } })
      console.log(error)

      return []
    })

export const listArticles = (): Promise<Article[]> =>
  axios
    .get('https://api.dev.pierias.com/articles')
    .then((response) => response.data.data.articles)
    .catch((error) => {
      captureException(error, { tags: { section: 'article' } })
      console.log(error)

      return []
    })

export const fetchArticle = (articleID: string): Promise<Article | null> =>
  axios
    .get(`https://api.dev.pierias.com/article/${articleID}`)
    .then((response) => response.data.article)
    .catch((error) => {
      captureException(error, {
        tags: { section: 'article' },
        contexts: {
          args: {
            articleID
          }
        }
      })

      return null
    })
