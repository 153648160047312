// import { createHash, randomUUID } from 'crypto'

import {
  ArticleAsset,
  ArticleAuthor,
  ArticleContent,
  ArticleData,
  ArticlePublisher,
  DynamoArticle,
  DynamoArticleContent
} from '@project/interfaces'

import { Content } from './content'

enum ArticleChecksumVersionAlgorithm {
  '1.0' = 'sha256'
}

export class Article {
  public static version: keyof typeof ArticleChecksumVersionAlgorithm = '1.0'
  public id: string
  public publisher?: ArticlePublisher
  public author?: ArticleAuthor
  public checksum: string
  public language: 'en' = 'en'
  public views = 0
  public shares = 0
  public votes = 0
  public timeCreated: Date
  public timeUpdated: Date | null = null
  public readDuration: number | null = null
  public active = false
  public flagged = false
  public feature?: string
  private rawContent: Content[] = []
  public assets: ArticleAsset[] = []
  public highlights: ArticleContent['key'][] = []
  public title: string | null = null
  public subtitle: string | null = null
  public banner: { src: string } | null = null

  constructor() {
    this.id = 'random' // randomUUID()
    this.language = 'en'
    this.timeCreated = new Date()
    this.checksum = ''

    this.update()
  }

  public update = (): Article | string => {
    const algorithm = ArticleChecksumVersionAlgorithm[Article.version]

    if (algorithm) {
      // return createHash(algorithm).update(this.id).digest('hex')
      return 'hash'
    }

    return this
  }

  public static fromRecord = (data: Record<string, unknown>): Article =>
    new Article()

  public static fromDynamoRecord = (record: DynamoArticle): Article => {
    const article = new Article()

    article.id = record.ArticleId
    article.title = record.Title
    article.subtitle = record.Subtitle
    article.banner = record.Banner
      ? {
          src: record.Banner.Src
        }
      : null

    return article
  }

  public toDynamoRecord = (): DynamoArticle => ({
    Version: Article.version,
    ArticleId: this.id,
    Banner: this.banner ? { Src: this.banner?.src } : null,
    EntityId: this.publisher?.id ?? '',
    AuthorId: this.publisher?.id ?? '',
    DateUpdated: this.timeUpdated?.toUTCString() || null,
    DateCreated: this.timeCreated.toUTCString(),
    Content: this.contentRecords,
    Title: this.title || null,
    Subtitle: this.subtitle || null,
    Language: this.language,
    Views: this.views,
    Votes: this.votes,
    Shares: this.shares,
    Active: this.active,
    Flagged: this.flagged,
    Checksum: this.checksum || null
  })

  public get data(): ArticleData {
    return {
      version: Article.version,
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      timeCreated: this.timeCreated.getTime(),
      timeUpdated: this.timeUpdated?.getTime(),
      author: this.author || { id: '', name: '' },
      checksum: this.checksum,
      language: this.language,
      views: this.views,
      votes: this.votes,
      shares: this.shares,
      readDuration: this.readDuration,
      active: this.active,
      flagged: this.flagged
    }
  }

  public get content(): ArticleContent[] {
    return this.rawContent.map((item) => item.data)
  }

  public get contentRecords(): DynamoArticleContent[] {
    return this.rawContent.map((item) => item.toDynamoRecord())
  }
}
